import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getCustomer, updateCustomer } from '../services/customer';  // Assume you have these API functions
import { FaAngleLeft } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { toast, ToastContainer } from 'react-toastify';
import Sidebar from './SideBar';
import Header from './Header';
import './css/dashboard.css';
import './css/AddCustomer.css';

const EditCustomer = () => {
  const { id } = useParams();
  const [customerData, setCustomerData] = useState({
    name: '',
    contact: '',
    city: '',
    openingDues: '',
    address: '',
  });

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await getCustomer(id); 
        setCustomerData(response.customer);
      } catch (error) {
        console.error('Error fetching customer data:', error);
        toast.error('Error fetching customer data.');
      }
    };
    fetchCustomer();
  }, [id]);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateCustomer(id, customerData); // Send updated data to backend
      toast.success('Customer updated successfully!');
      
    } catch (error) {
      console.error('Error updating customer:', error);
      toast.error('Error updating customer. Please try again.');
    }
  };

  return (
    <div className="dashboard-root">
      <Header/>
      <div className="main-d">
        <Sidebar/>
        <ToastContainer 
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        <div className="add-customer-main">
          <div className="add-customer-top">
            <button className="add-customer-back">
            <Link to="/customer">
                <div style={{display: 'flex', alignItems:'center', gap:'5px'}}>
                    <FaAngleLeft/> 
                    <p>Back</p>
                </div>
            </Link>
            </button>
            <button className="add-customer-close">
            <Link to="/dashboard">
                  <div style={{display: 'flex', alignItems:'center', gap:'5px'}}>
                      <p>Close</p>
                      <FaXmark/>
                  </div>
              </Link>
          </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="add-customer-first-row">
              <div className="add-customer-name">
                <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Customer Name</label>
                <input
                  type="text"
                  name="name"
                  className="customer-first-row-input"
                  value={customerData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="add-customer-phone">
                <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Contact Number</label>
                <input
                  type="text"
                  name="contact"
                  className="customer-first-row-input"
                  value={customerData.contact}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="add-customer-city">
                <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>City</label>
                <input
                  type="text"
                  name="city"
                  className="customer-first-row-input"
                  value={customerData.city}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="add-customer-second-row">
              <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Opening Dues</label>
              <input
                type="text"
                name="openingDues"
                className="opening-dues-input"
                value={customerData.openingDues}
                onChange={handleChange}
                required
              />
            </div>
            <div className="add-customer-third-row">
              <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Customer Address</label>
              <textarea
                name="address"
                className="add-customer-address-input"
                value={customerData.address}
                onChange={handleChange}
                rows="4"
                required
              />
            </div>
            <div className="add-customer-fourth-row">
              <button className="add-customer-cancel" type="button">
              <Link to={'/customer'}>Cancel</Link>
              </button>
              <button className="add-customer-save" type="submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditCustomer;
