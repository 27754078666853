import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "./css/DBProductPurchase.css";

// Register ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend);

const ProductPurchasePage = () => {
  const [startDate, setStartDate] = useState("2022-12-01");
  const [endDate, setEndDate] = useState("2022-12-06");

  // Sample data for the donut chart
  const data = {
    datasets: [
      {
        data: [30, 50, 20], // Adjust the percentages as required
        backgroundColor: ["#5A6ACF", "#8593ED", "#C7CEFF"],
      },
    ],
  };

  // Options for the donut chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  // Product purchase percentages
  const months = [
    { month: "3rd month", percentage: 40 },
    { month: "2nd month", percentage: 50 },
    { month: "1st month", percentage: 60 },
  ];

  return (
    <div className="product-purchase-container">
      <div className="Product-purchase-page-header">
        <h1>
          <strong>Product Purchase</strong>
        </h1>
        <button className="Product-purchase-view-report-btn">View Report</button>
      </div>

      <div className="Product-purchase-date-selector">
        <label>
          From:{" "}
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>
        <label>
          To:{" "}
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </label>
      </div>

      <div className="chart-container">
        <Doughnut data={data} options={options} />
      </div>

      <div className="month-item1">
        <label>Last months:</label>
      </div>

      <div className="months-container">
        {months.map(({ month, percentage }) => (
          <div className="month-item" key={month}>
            <lable>{month}</lable>
            <p>{percentage}%</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductPurchasePage;
