import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = ({ onForgot, onClose }) => {
  const [username, setUsername] = useState('admin'); // Pre-fill with demo username
  const [password, setPassword] = useState('admin'); // Pre-fill with demo password
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    let hasError = false;

    if (username.trim() === '') {
      toast.error('Please enter your username.');
      hasError = true;
    }

    if (password.trim() === '') {
      toast.error('Please enter your password.');
      hasError = true;
    }

    if (hasError) return;

    // Hardcoded validation for demo credentials
    if (username === 'admin' && password === 'admin') {
      toast.success('Login Successful!');
      console.log("Login Successful");
      navigate('/dashboard'); // Redirect to dashboard
    } else {
      toast.error('Invalid username or password!');
      console.log("Login Failed");
    }
  };

  return (
    <div className="text-center w-full">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <button
        className="" style={{ marginLeft: '90%', fontWeight: 'bolder' }}
        onClick={onClose}
      >
        X
      </button>
      <h1 className="text-black text-2xl font-bold font-Poppins mb-6">
        <b>Innovativ<span style={{ color: 'rgb(234, 209, 0, 1)' }}>Soft</span></b>
      </h1>
      <h2 className="text-2xl font-bold mb-5">Login</h2>
      <form className="py-16" onSubmit={handleSubmit}>
        <div className="mb-32">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 text-left"
            style={{ marginTop: '-3rem' }}
          >
            Username
          </label>
          <input
            type="text"
            className="shadow appearance-none border rounded w-64 h-10 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Type Your Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 text-left"
            style={{ marginTop: '-6.5rem' }}
          >
            Password
          </label>
          <input
            type="password"
            className="shadow appearance-none border rounded w-64 h-10 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Type Your Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="text-center mb-4">
          <button
            className="bg-gradient-to-r from-blue-500 via-purple-600 to-pink-500 hover:from-blue-500 hover:to-purple-500 text-white font-bold py-2 px-6 rounded-full focus:outline-none"
            type="submit"
          >
            Login
          </button>
        </div>
        <p onClick={onForgot} style={{ cursor: 'pointer', textDecoration: 'underline' }}>Forgot Username or Password</p>
      </form>
    </div>
  );
};

export default Login;
