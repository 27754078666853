import React, { useEffect, useState } from 'react';
import Header from './Header';
import Sidebar from './SideBar';
import './css/dashboard.css';
import './css/Material.css';
import './css/pagination.css'
import { getMaterial, addMaterial, deleteMaterial, updateMaterial } from '../services/materials';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { HiOutlineCheckCircle } from "react-icons/hi"
import { toast, ToastContainer } from 'react-toastify'


const Material = () => {
  const [materials, setMaterials] = useState([]);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [query, setQuery] = useState('');
  const [materialTypes, setMaterialTypes] = useState([]);
  const [selectedMaterialType, setSelectedMaterialType] = useState('All');
  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false);
  const [materialname, setMaterialname] = useState('');
  const [materialtype, setMaterialtype] = useState('');
  const [rate, setRate] = useState('');
  const [unit, setUnit] = useState('');
  const [stock, setStock] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false); 
  const [showModal, setShowModal] = useState(false);
  const [materialToDelete, setMaterialToDelete] = useState(null); 
  const [showEditMaterialModal, setShowEditMaterialModal] = useState(false);
  const [materialToEdit, setMaterialToEdit] = useState(null);

  const materialPerPage = 10;

  const fetchMaterials = async () => {
    try {
      const response = await getMaterial();
      const fetchedMaterials = response.material || []; // fallback to empty array
      setMaterials(fetchedMaterials);
      setFilteredMaterials(fetchedMaterials);  // Set filteredMaterials here
      const uniqueTypes = [...new Set(fetchedMaterials.map((material) => material.materialType))];
      setMaterialTypes(uniqueTypes.sort());
    } catch (error) {
      console.error('Error fetching materials:', error);
    }
  };

  useEffect(() => {
    fetchMaterials();
  }, []);

  useEffect(() => {
    const filteredData = materials.filter((material) =>
      material.materialName.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredMaterials(filteredData);  // Update filteredMaterials based on query
  }, [query, materials]);

  const handleAdd = async () => {
    if (!materialname || !materialtype || !rate || !stock || !unit) {
      alert('Please fill all the fields');
      return;
    }

    const materialData = {
      name: materialname,
      type: materialtype,
      rate: parseFloat(rate),
      stock: parseInt(stock, 10),
      unit,
    };
    
    try {
      const result = await addMaterial(materialData);
      alert('Material added successfully');
      setMaterialname('');
      setMaterialtype('');
      setRate('');
      setStock('');
      setUnit('');
      setShowAddMaterialModal(false);
      fetchMaterials();
    } catch (error) {
      alert('Failed to add material. Please try again.');
      console.error(error);
    }
  };

  const handleDeleteClick = (id) => {
    setMaterialToDelete(id); 
    setShowModal(true); 
  };

  const handleDelete = async () => {
    if (!materialToDelete) return;

    try {
      const result = await deleteMaterial(materialToDelete); 
      const updatedMaterials = materials.filter((material) => material._id !== materialToDelete);
      setMaterials(updatedMaterials);
      setFilteredMaterials(updatedMaterials);
      setShowModal(false); 
      setMaterialToDelete(null); 
      setShowSuccessModal(true); 
    } catch (error) {
      console.error('Error deleting material:', error);
      alert('An error occurred while deleting the material.');
    }
  };

  const handleUpdateMaterial = async () => {
    if (!materialToEdit) return;

    try {
      const updatedMaterial = await updateMaterial(materialToEdit);
      fetchMaterials();
      setShowEditMaterialModal(false);
      toast.success('Material Updated Successfully');
    } catch (error) {
      console.error("Error updating material:", error);
      toast.error("Failed to update material.");
    }
  };

  const handleModalClose = () => {
    setShowModal(false); 
    setMaterialToDelete(null); 
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false); 
  };

  const handleEditClick = (material) => {
    setMaterialToEdit({
      _id: material._id,
      materialName: material.materialName,
      materialType: material.materialType, 
      rate: material.rate,
      stock: material.stock,
      unit: material.unit,
    });
    setShowEditMaterialModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMaterialToEdit((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.length >= 2) {
      const filteredData = materials.filter((material) =>
        material.materialName.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredMaterials(filteredData);
    } else {
      setFilteredMaterials(materials);  
    }
    setCurrentPage(1); 
  };

  const handleMaterialTypeChange = (event) => {
    const selectedType = event.target.value;
    setSelectedMaterialType(selectedType);

    if (selectedType === 'All') {
      setFilteredMaterials(materials);  
    } else {
      const filteredData = materials.filter((material) => material.materialType === selectedType);
      setFilteredMaterials(filteredData);  
    }
    setCurrentPage(1);  
  };

  const indexOfLastMaterial = currentPage * materialPerPage;
  const indexOfFirstMaterial = indexOfLastMaterial - materialPerPage;

  const currentMaterials = filteredMaterials.slice(indexOfFirstMaterial, indexOfLastMaterial);
  const totalPages = Math.ceil(filteredMaterials.length / materialPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    
    if (currentPage > 2) {
      pageNumbers.push(1);
      if (currentPage > 3) {
        pageNumbers.push("...");
      }
    }
  
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 1);
  
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    if (currentPage < totalPages - 1) {
      if (currentPage < totalPages - 2) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages);
    }
  
    return pageNumbers.map((page, index) =>
      typeof page === "number" ? (
        <button
          key={index}
          className={`pagination-button ${currentPage === page ? "active-page" : ""}`}
          onClick={() => paginate(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-ellipsis">{page}</span>
      )
    );
  };
  

  return (
    <div className="dashboard-root">
      <Header />
      <div className="main-d">
        <Sidebar />
        <ToastContainer 
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        {showEditMaterialModal && materialToEdit && (
        <div className="popup-overlay">
          <div className="popup-container">
            <h2 style={{ height: '60px', fontSize: 'x-large', fontWeight: 'bold', marginTop: '2%', color: '#007bff' }}>
              Edit Material
            </h2>
            <div className="edit-material-first-row">
              <div className="material-edit-first">
                <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Material</label>
                <input
                  className="material-name-input"
                  type="text"
                  name="materialName" 
                  value={materialToEdit.materialName || ""} 
                  onChange={handleInputChange} 
                  placeholder="Enter Material"
                  disabled={false}
                />
              </div>
            </div>
            <div className="edit-material-second-row">
              <div className="edit-material-second-left">
                <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Type</label>
                <select
                  className="material-type-input"
                  name="type"
                  value={materialToEdit.materialType || ""}
                  onChange={handleInputChange}
                >
                  <option value="">Select Material Type</option>
                  <option value="Wood">Wood</option>
                  <option value="Metal">Metal</option>
                  <option value="Plastic">Plastic</option>
                  <option value="Glass">Glass</option>
                  <option value="Fabric">Fabric</option>
                </select>
              </div>
              <div className="edit-material-second-right">
                <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Enter rate</label>
                <input
                  type="number"
                  name="rate"
                  value={materialToEdit.rate || ""}
                  onChange={handleInputChange}
                  placeholder="Material Amount"
                  className="material-amount-input"
                />
              </div>
            </div>
            <div className="edit-material-third-row">
              <div className="edit-material-third-left">
                <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Stock</label>
                <input
                  type="number"
                  name="stock"
                  value={materialToEdit.stock || ""}
                  onChange={handleInputChange}
                  placeholder="Enter Stock"
                  className="edit-material-stock-input"
                />
              </div>
              <div className="edit-material-third-right">
                <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Unit</label>
                <select
                  className="edit-material-unit-input"
                  name="unit"
                  value={materialToEdit.unit || ""}
                  onChange={handleInputChange}
                >
                  <option value="">Select Stock</option>
                  <option value="Brass">Brass</option>
                  <option value="Ton">Ton</option>
                </select>
              </div>
            </div>
            <div className="edit-material-form-btn">
              <button
                type="button"
                className="edit-material-close-btn"
                onClick={() => setShowEditMaterialModal(false)}
              >
                Close
              </button>
              <button
                type="button"
                className="edit-material-update-btn"
                onClick={handleUpdateMaterial}
              >
                Update
              </button>
            </div>
          </div>
        </div>
        )}
        {showAddMaterialModal && (
          <div className="popup-overlay">
            <div className="popup-container">
              <h2 style={{height: '60px', fontSize:'x-large', fontWeight:'bold', marginTop:'2%', color:'#007bff'}}>New Material</h2>
              <div className='add-material-first-row'>
                <div className="material-add-first">
                  <label style={{fontWeight:'bold', marginBottom:'5px'}}>Material</label>
                  <input
                    className="material-name-input"
                    type="text"
                    id="material"
                    value={materialname}
                    onChange={(e) => setMaterialname(e.target.value)}
                    placeholder="Enter Material"
                  />
                </div>
              </div>
              <div className='add-material-second-row'>
                <div className="add-material-second-left">
                  <label style={{fontWeight:'bold', marginBottom:'5px'}}>Type</label>
                  <select
                    id="type"
                    value={materialtype} 
                    onChange={(e) => setMaterialtype(e.target.value)} 
                    className="material-type-input"
                  >
                    <option value="">Select Material Type</option>
                    <option value="Wood">Wood</option>
                    <option value="Metal">Metal</option>
                    <option value="Plastic">Plastic</option>
                    <option value="Glass">Glass</option>
                    <option value="Fabric">Fabric</option>
                  </select>
                </div>
                <div className="add-material-second-right">
                  <label style={{fontWeight:'bold', marginBottom:'5px'}}>Enter rate</label>
                  <input
                    type="number"
                    id="rate"
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                    placeholder="Material Amount"
                    className="material-amount-input"
                  />
                </div>
              </div>
              <div className='add-material-third-row'>
                <div className="add-material-third-left">
                  <label style={{fontWeight:'bold', marginBottom:'5px'}}>Stock</label>
                  <input
                    type="number"
                    id="stock"
                    value={stock}
                    onChange={(e) => setStock(e.target.value)}
                    placeholder="Enter Stock"
                    className="add-material-stock-input"
                  />
                </div>
                <div className="add-material-third-right">
                  <label style={{fontWeight:'bold', marginBottom:'5px'}}>Unit</label>
                  <select
                    id="unit"
                    value={unit}
                    onChange={(e) => setUnit(e.target.value)}
                    className="add-material-unit-input"
                  >
                    <option value="">Select Stock</option>
                    <option value="Brass">Brass</option>
                    <option value="Ton">Ton</option>
                  </select>
                </div>
              </div>
              <div className='add-material-form-btn'>
                <button
                  type="button"
                  onClick={() => setShowAddMaterialModal(false)}
                  className="add-material-close-btn"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="add-material-save-btn"
                  onClick={handleAdd}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
        {showModal && (
          <div className="delete-modal">
            <div className="modal-content">
              <div className="model-main-content">
                <div className="model-image">
                  <HiOutlineCheckCircle
                    style={{ width: '55px', height: '55px', color: 'gray', marginTop: '5px', marginLeft: '5px' }}
                  />
                </div>
                <div className="model-text">
                  <h1 style={{ fontSize: 'x-large', fontWeight: 'bold', color: '#007bff' }}>Sure you want to delete?</h1>
                  <p>Are you sure you want to delete this material?</p>
                </div>
              </div>
              <div className="model-btn">
                <button onClick={handleModalClose} className="cancel-btn">
                  No, Cancel
                </button>
                <button onClick={handleDelete} className="confirm-btn">
                  Yes, Confirm
                </button>
              </div>
            </div>
          </div>
        )}
        {showSuccessModal && (
          <div className="success-modal">
            <div className="modal-content">
              <HiOutlineCheckCircle
                style={{ width: '80px', height: '80px', color: 'green', marginTop: '5px', marginLeft: '160px' }}
              />
              <h1 style={{ fontSize: 'x-large', fontWeight: 'bold', marginTop: '25px', color: '#007bff' }}>
                Deleted Successfully!
              </h1>
              <p>Selected material data has been deleted from the database successfully.</p>
              <button onClick={handleSuccessModalClose} className="close-btn">
                Done
              </button>
            </div>
          </div>
        )}
        <div className="material-main">
          <div className="material-main-top">
            <div className="m-top-btn">
              <button className="new-material-btn" onClick={() => setShowAddMaterialModal(true)}>New Material</button>
              <button className="material-note-btn">Note</button>
            </div>
            <div className="m-top-input">
            <div className="m-select-input">
              <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Search Type</label>
              <select
                className="select-options"
                value={selectedMaterialType}
                onChange={handleMaterialTypeChange}
              >
                <option value="All">All</option>
                {materialTypes.length > 0 ? (
                  materialTypes.map((type, index) => (
                    <option key={index} value={type}>{type}</option>
                  ))
                ) : (
                  <option value="">Loading material types...</option>
                )}
              </select>
            </div>
            <div className="m-search-input">
              <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Search Material</label>
              <input
                className="material-search-input"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          </div>
          <div className="material-table">
            <div className="overflow-x-auto" style={{ borderRadius: '8px' }}>
              <table className="material-table-data">
                <thead>
                  <tr className="material-table-heading">
                    <th>Sr No.</th>
                    <th>Name</th>
                    <th>Stock</th>
                    <th>Unit</th>
                    <th>Type</th>
                    <th>Rate</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="material-data">
                  {currentMaterials.map((material, index) => (
                    <tr key={material._id || index} className="material-data-main">
                      <td>{(currentPage - 1) * materialPerPage + index + 1}</td>
                      <td>{material.materialName}</td>
                      <td>{material.stock}</td>
                      <td>{material.unit}</td>
                      <td>{material.materialType}</td>
                      <td>{material.rate}</td>
                      <td>
                        <button 
                          style={{ color: '#007bff', margin: '0 5px' }}
                          onClick={() => handleEditClick(material)} 
                        >
                          <FaEdit />
                        </button>
                        <button 
                          style={{ color: 'red', margin: '0 5px' }}
                          onClick={() => handleDeleteClick(material._id)} 
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="pagination">
            <button
                className="pagination-button"
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
              {renderPageNumbers()}
              <button
                className="pagination-button"
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                {">"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Material;
