import React, { useState, useEffect } from "react";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import "./css/RevenuePage.css";

ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

const RevenuePage = () => {
  const [startDate, setStartDate] = useState("2022-12-01");
  const [endDate, setEndDate] = useState("2022-12-06");
  const [timeframe, setTimeframe] = useState("6days");

  const revenueData = {
    "6days": {
      labels: ["1 Dec", "2 Dec", "3 Dec", "4 Dec", "5 Dec", "6 Dec"],
      current: [500, 600, 550, 700, 650, 800],
      previous: [450, 500, 480, 620, 600, 750],
    },
    week: {
      labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
      current: [3000, 3200, 3500, 4000],
      previous: [2800, 3100, 3300, 3800],
    },
  };

  const filterRevenueData = () => {
    const data = revenueData[timeframe];

    if (timeframe === "6days") {
      const filteredData = data.labels.reduce(
        (acc, label, index) => {
          const labelDate = new Date(`2022-12-${label.split(" ")[0]}`);
          const start = new Date(startDate);
          const end = new Date(endDate);

          if (labelDate >= start && labelDate <= end) {
            acc.labels.push(label);
            acc.current.push(data.current[index]);
            acc.previous.push(data.previous[index]);
          }
          return acc;
        },
        { labels: [], current: [], previous: [] }
      );
      return filteredData;
    }

    return data;
  };

  const filteredData = filterRevenueData();
  const totalCurrentRevenue = filteredData.current.reduce((a, b) => a + b, 0);
  const totalPreviousRevenue = filteredData.previous.reduce((a, b) => a + b, 0);

  const percentageChange = (
    ((totalCurrentRevenue - totalPreviousRevenue) / totalPreviousRevenue) *
    100
  ).toFixed(1);

  const isIncrease = totalCurrentRevenue > totalPreviousRevenue;

  const handleTimeframeChange = (value) => {
    setTimeframe(value);
  };

  const lineChartData = {
    labels: filteredData.labels,
    datasets: [
      {
        label: "Current Revenue",
        data: filteredData.current,
        fill: false,
        tension: 0.4,
      },
      {
        label: "Previous Revenue",
        data: filteredData.previous,
        fill: false,
        tension: 0.4,
      },
    ],
  };
  const barChartData = {
    labels: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
    datasets: [
      {
        data: [200, 400, 300, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300],
        backgroundColor: "#cdbc24",
      },
      {
        data: [150, 350, 250, 450, 550, 650, 750, 850, 950, 1050, 1150, 1250],
        backgroundColor: "#e6e8ec",
      },
    ],
  };
  

  useEffect(() => {
    // Re-filter data on date or timeframe change
  }, [startDate, endDate, timeframe]);

  return (
    <div className="revenue-page-container">
      <div className="revenue-page-section">
        <div className="revenue-page-header">
          <h1>
            <strong>Revenue</strong>
          </h1>
          <button className="revenue-view-report-btn">View Report</button>
        </div>

        <div className="revenue-page-summary">
          <h3>INR {totalCurrentRevenue}</h3>
          <p>
            <span
              className={`revenue-percentage-change ${
                isIncrease ? "up" : "down"
              }`}
              style={{
                color: isIncrease ? "green" : "red",
              }}
            >
              {isIncrease ? "↑" : "↓"} {Math.abs(percentageChange)}%
            </span>{" "}
            vs Last week
          </p>
        </div>

        <div className="revenue-date-selector">
          <label>
            Start Date:{" "}
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </label>
          <label>
            End Date:{" "}
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </label>
        </div>
        <div className="revenue-bar-chart-container">
  <Bar
    data={barChartData}
    options={{
      responsive: true,
      plugins: {
        legend: {
          display: false, // Disable the legend entirely
        },
      },
      scales: {
        x: {
          grid: {
            display: false, // Removes vertical grid lines
          },
        },
        y: {
          // grid: {
          //   display: false, // Removes horizontal grid lines
          // },
          ticks: {
            display: true, // Ensure Y-axis values still appear
          },
        },
      },
    }}
  />
</div>

        <div className="revenue-timeframe-selector">
          <label>
            <input
              type="radio"
              name="revenue-timeframe"
              value="6days"
              checked={timeframe === "6days"}
              onChange={() => handleTimeframeChange("6days")}
            />
            Last 6 days
          </label>

          <label>
            <input
              type="radio"
              name="revenue-timeframe"
              value="week"
              checked={timeframe === "week"}
              onChange={() => handleTimeframeChange("week")}
            />
            last week
          </label>
        </div>
      </div>
    </div>
  );
};

export default RevenuePage;
