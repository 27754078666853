import React, { useState } from 'react'
import './css/dashboard.css'
import Sidebar from './SideBar'
import Header from './Header'
import './css/AddVehicle.css'
import { FaAngleLeft } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { Link } from 'react-router-dom'
import { addVehicle } from '../services/vehicle'

const AddVehicle = () => {
    const [ownerName, setOwnerName] = useState('');
    const [contact, setContact] = useState('');
    const [city, setCity] = useState('');
    const [ownerShip, setOwnerShip] = useState('');
    const [vehicleNumber, setVehicleNumber] = useState('');
    const [vehicleWeight, setVehicleWeight] = useState('');
    const [vehicleCapacity, setVehicleCapacity] = useState('');
    const [modelYear, setModelYear] = useState('');
    const [rc, setRc] = useState('');
    const [ownerAddress, setOwnerAddress] = useState('');


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('ownerName', ownerName);
        formData.append('contact', contact);
        formData.append('city', city);
        formData.append('ownerShip', ownerShip);
        formData.append('vehicleNumber', vehicleNumber);
        formData.append('vehicleWeight', vehicleWeight);
        formData.append('vehicleCapacity', vehicleCapacity);
        formData.append('modelYear', modelYear);
        formData.append('ownerAddress', ownerAddress);
        if (rc) formData.append('rc', rc);
    
        try {
            const response = await addVehicle(formData);  
            console.log('Vehicle data', response);
        } catch (error) {
            console.log('Error adding vehicle:', error);
        }
    };
    
    
    


  return (
    <div className="dashboard-root">
      <Header />
      <div className="main-d">
        <Sidebar />
        <div className="add-vehicle-main">
            <div className='add-vehicle-top'>
                <div className='add-vehicle-top-left'>
                    <button className='add-customer-back'>
                        <Link to="/vehicle">
                            <div style={{display: 'flex', alignItems:'center', gap:'5px'}}>
                                <FaAngleLeft/> 
                                <p>Back</p>
                            </div>
                        </Link>
                    </button>
                </div>
                <div className='add-vehicle-top-right'>
                    <button className='add-customer-close'>
                        <Link to="/dashboard">
                            <div style={{display: 'flex', alignItems:'center', gap:'5px'}}>
                                <p>Close</p>
                                <FaXmark/>
                            </div>
                        </Link>
                    </button>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='add-vehicle-first-input'>
                    <div className='a-v-owner-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Name of Owner</label>
                        <input
                            className='av-owner-input'
                            placeholder='Name of Owner'
                            value={ownerName}
                            onChange={(e) => setOwnerName(e.target.value)}
                        />
                    </div>
                    <div className='a-v-contact-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Conatct No.</label>
                        <input
                            className='av-contact-input'
                            placeholder='Contact Number'
                            value={contact}
                            onChange={(e) => setContact(e.target.value)}
                        />
                    </div>
                    <div className='a-v-city-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>City</label>
                        <input
                            className='av-city-input'
                            placeholder='City'
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </div>
                    <div className='a-v-status-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Rented/Owned</label>
                        <select
                            className='av-status-input'
                            value={ownerShip}
                            onChange={(e) => setOwnerShip(e.target.value)}
                        >
                            <option>Choose a Option</option>
                            <option>Rented</option>
                            <option>Owned</option>
                        </select>
                    </div>
                </div>
                <div className='add-vehicle-second-input'>
                    <div className='a-v-number-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Vehicle Number</label>
                        <input
                            className='av-number-input'
                            placeholder='Vehicle No.'
                            value={vehicleNumber}
                            onChange={(e) => setVehicleNumber(e.target.value)}
                        />
                    </div>
                    <div className='a-v-weight-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Vehicle Weight</label>
                        <input
                            className='av-weight-input'
                            placeholder='Vehicle Weight'
                            value={vehicleWeight}
                            onChange={(e) => setVehicleWeight(e.target.value)}
                        />
                    </div>
                    <div className='a-v-capacity-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Vehicle Capacity</label>
                        <input
                            className='av-capacity-input'
                            placeholder='Vehicle Capacity'
                            value={vehicleCapacity}
                            onChange={(e) => setVehicleCapacity(e.target.value)}
                        />
                    </div>
                    <div className='a-v-model-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Model of Year</label>
                        <input
                            className='av-model-input'
                            value={modelYear}
                            onChange={(e) => setModelYear(e.target.value)}
                        />
                    </div>
                    <div className='a-v-rc-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>RC Book Upload</label>
                        <input
                            className='av-rc-input'
                            type='file'
                            name='rc'
                            onChange={(e) => setRc(e.target.files[0])}
                        />
                    </div>
                </div>
                <div className='add-vehicle-third-input'>
                    <div className='a-v-address-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Owner Address</label>
                        <textarea
                            className='av-address-input'
                            placeholder='Owner Address'
                            rows="4"
                            value={ownerAddress}
                            onChange={(e) => setOwnerAddress(e.target.value)}
                        >
                        </textarea>
                    </div>
                </div>
                <div className='add-vehicle-bottom'>
                    <button className='add-vehicle-cancel'>
                        <Link to="/vehicle">Cancel</Link>
                    </button>
                    <button type='submit' className='add-vehicle-save'>Save</button>
                </div>
            </form>
        </div>
       </div>
    </div> 
  )
}

export default AddVehicle;