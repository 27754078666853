import React, { useEffect, useState } from 'react';
import './css/dashboard.css';
import Sidebar from './SideBar';
import './css/Vehicle.css';
import Header from './Header';
import { Link } from 'react-router-dom';
import { getVehicle, deleteVehicle } from '../services/vehicle'; 
import { FaRegEye, FaTrash, FaEdit } from "react-icons/fa";
import { HiOutlineCheckCircle } from "react-icons/hi"

const Vehicle = () => {
  const [vehicles, setVehicles] = useState([]);
  const [cities, setCities] = useState([]);
  const [filteredCity, setFilteredVehicles] = useState([]);
  const [city, setCity] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [vehicleToDelete, setvehicleToDelete] = useState(null); 
  const [showSuccessModal, setShowSuccessModal] = useState(false);   
  const itemsPerPage = 10; // Number of items per page



  const handleDeleteClick = (id) => {
    setvehicleToDelete(id);  
    setShowModal(true);  
  };
  
  const handleSuccessModalClose = () => {
    setShowSuccessModal(false); 
  }; 

  const handleModalClose = () => {
    setShowModal(false); 
    setvehicleToDelete(null);
  };


  const handleDelete = async (id) => {
    try {
      await deleteVehicle(vehicleToDelete);
      setVehicles((prevVehicles) => prevVehicles.filter((vehicle) => vehicle._id !== vehicleToDelete));
      setFilteredVehicles((prevFiltered) => prevFiltered.filter((vehicle) => vehicle._id !== vehicleToDelete));
      setShowModal(false)
      setShowSuccessModal(true)
    } catch (error) {
      console.error('Error deleting vehicle:', error);
      alert(error.error || 'Failed to delete vehicle');
    }
  }

  // Fetch vehicles on component mount
  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await getVehicle();
        setVehicles(response.vehicles);
        setFilteredVehicles(response.vehicles); // Initialize filteredCity with all vehicles
      } catch (error) {
        console.error('Error fetching vehicles:', error);
      }
    };

    fetchVehicles();
  }, []);

  // Extract unique cities when vehicles data is updated
  useEffect(() => {
    if (vehicles.length > 0) {
      const uniqueCities = [...new Set(vehicles.map((vehicle) => vehicle.city))];
      const sortedCities = uniqueCities.sort();
      setCities(sortedCities);
    }
  }, [vehicles]);

  // Handle city filter changes
  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    setCity(selectedCity);

    if (selectedCity === 'All') {
      setFilteredVehicles(vehicles);
    } else {
      const filteredData = vehicles.filter((vehicle) => vehicle.city === selectedCity);
      setFilteredVehicles(filteredData);
    }
    setCurrentPage(1); // Reset to first page when city changes
  };

  // Handle vehicle number search
  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term.trim() === '') {
      if (city === 'All' || city === '') {
        setFilteredVehicles(vehicles);
      } else {
        const filteredData = vehicles.filter((vehicle) => vehicle.city === city);
        setFilteredVehicles(filteredData);
      }
    } else {
      const filteredData = vehicles.filter((vehicle) =>
        vehicle.vehicleNumber.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredVehicles(filteredData);
    }
    setCurrentPage(1); // Reset to first page when search changes
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentVehicles = filteredCity.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredCity.length / itemsPerPage);

  const paginate = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Dynamic Pagination with Ellipses
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // Number of pages to show before adding ellipses

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pageNumbers.push(1, 2, 3, 4, '...', totalPages);
      } else if (currentPage > totalPages - 3) {
        pageNumbers.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        pageNumbers.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
      }
    }
    return pageNumbers;
  };

  const renderPagination = () => {
    const pageNumbers = getPageNumbers();
  
    return pageNumbers.map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`pagination-button ${currentPage === page ? 'active-page' : ''}`}
          onClick={() => paginate(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-ellipsis">{page}</span>
      )
    );
  };
  
  return (
    <div className="dashboard-root">
      <Header />
      <div className="main-d">
        <Sidebar />
        <div className="vehicle-main">
            {showModal && (
              <div className="delete-modal">
                <div className="modal-content">
                  <div className='model-main-content'>
                    <div className='model-image'>
                      <HiOutlineCheckCircle style={{width:'55px', height:'55px', color: 'gray', marginTop:'5px', marginLeft:'5px'}}/>
                    </div>
                    <div className='model-text'>
                      <h1 style={{fontSize:'x-large', fontWeight:'bold', color:'#007bff'}}>Sure you want to delete?</h1>
                      <p>Are you sure you want to delete this vehicle details?</p>
                    </div>
                  </div>
                  <div className='model-btn'>
                    <button onClick={handleModalClose} className="cancel-btn">No, Cancel</button>
                    <button onClick={handleDelete} className="confirm-btn">Yes, Confirm</button>
                  </div>
                </div>
              </div>
            )}
            {showSuccessModal && (
              <div className="success-modal">
                <div className="modal-content">
                  <HiOutlineCheckCircle style={{width:'80px', height:'80px', color: 'green', marginTop:'5px', marginLeft:'160px'}}/>
                  <h1 style={{fontSize:'x-large', fontWeight: 'bold', marginTop:'25px', color:"#007bff"}}>Deleted Successfully!</h1>
                  <p>Selected vehicle data is deleted from the database<br/>Successfully.</p>
                  <button onClick={handleSuccessModalClose} className="close-btn">
                    Done
                  </button>
                </div>
              </div>
            )}
          <div className="vehicle-main-top">
            <div className="v-top-btn">
              <button className="new-vehicle-btn">
                <Link to="/add-vehicle">Add Vehicle</Link>
              </button>
              <button className="vehicle-note-btn">Note</button>
            </div>
            <div className="v-top-input">
              <div className="v-select-input">
                <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Search City</label>
                <select
                  className="select-options"
                  value={city}
                  onChange={handleCityChange}
                >
                  <option value="All">All</option>
                  {cities.length > 0 ? (
                    cities.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))
                  ) : (
                    <option value="">Loading cities...</option>
                  )}
                </select>
              </div>
              <div className="v-search-input">
                <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Search Vehicle</label>
                <input
                  className="vehicle-search-input"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Enter Vehicle Number"
                />
              </div>
            </div>
          </div>
          <div className="material-table">
            <div className="overflow-x-auto" style={{ borderRadius: '8px' }}>
              <table className="vehicle-table-data">
                <thead>
                  <tr className="vehicle-table-heading">
                    <th>Sr No.</th>
                    <th>Vehicle No.</th>
                    <th>Owner</th>
                    <th>Rented/Owned</th>
                    <th>Model Year</th>
                    <th>Vehicle Weight</th>
                    <th>Vehicle Capacity</th>
                    <th>RC Book Upload</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className='vehicle-data'>
                  {currentVehicles.length > 0 ? (
                    currentVehicles.map((vehicle, index) => (
                      <tr key={vehicle._id} className='vehicle-data-main'>
                        <td>{indexOfFirstItem + index + 1}</td>
                        <td>{vehicle.vehicleNumber}</td>
                        <td>{vehicle.ownerName}</td>
                        <td>{vehicle.ownerShip}</td>
                        <td>{vehicle.modelYear}</td>
                        <td>{vehicle.vehicleWeight}</td>
                        <td>{vehicle.vehicleCapacity}</td>
                        <td>
                          <a href={`/${vehicle.rc}`} target="_blank" rel="noopener noreferrer">
                            <button style={{ color: 'gray', margin: '0 5px' }}>
                              <FaRegEye />
                            </button>
                          </a>
                        </td>
                        <td>
                        
                            <button style={{ color: '#007bff', margin: '0 5px' }}>
                              <FaEdit />
                            </button> 
                          
                          
                          <button 
                            style={{ color: 'red', margin: '0 5px' }}
                            onClick={() => handleDeleteClick(vehicle._id)}
                          >
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9">No vehicles found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="pagination">
            <button
              className="pagination-button"
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              {"<"}
            </button>
            {renderPagination()}
            <button
              className="pagination-button"
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              {">"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vehicle;
