import { myAxios } from './helper';

export const addMaterial = async (data) => {
  try {
    const response = await myAxios.post('/api/materials/add', data);
    return response.data;
  } catch (error) {
    console.error('Error adding material:', error.response?.data?.message || error.message);
    throw error;
  }
};
  
export const getMaterial = async () => {
  try {
    const response = await myAxios.get('/api/materials/material');
    return response.data;
  } catch (error) {
    console.error('Error fetching material details:', error.response.data.message || error.message);
    throw error;
  }
};
  

export const deleteMaterial = async (id) => {
  try {
    const response = await myAxios.delete(`/api/materials/delete/${id}`);
    return response.data; 
  } catch (error) {
    console.error('Error deleting material:', error.response?.data?.message || error.message);
    throw error; 
  }
};


export const updateMaterial = async (material) => {
  const response = await myAxios.put(`/api/materials/edit/${material._id}`, material);
  return response.data;
};


  