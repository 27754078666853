import { myAxios } from './helper'; 

export const sendOtp = async (email) => {
  try {
    const response = await myAxios.post('/api/forgot/generate-otp', { email });
    return response.data; 
  } catch (error) {
    throw error; 
  }
};

export const verifyOtp = async (email, otp) => {
  try {
    const response = await myAxios.post('/api/forgot/verify-otp', { email, otp });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCredential = async (email, newCredential, credentialType) => {
  try {
    const response = await myAxios.post('/api/forgot/update-credentials', {
      email,
      newCredential,
      type: credentialType,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
