import React, { useEffect, useState, useRef } from "react";
import "./css/dashboard.css";
import "./css/Supplier.css"; // Create a new CSS file for suppliers
import Header from "./Header";
import Sidebar from "./SideBar";
import { Link } from "react-router-dom";
import { FaTrash, FaEdit, FaWhatsapp, FaPrint } from "react-icons/fa";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";

const Supplier = () => {
  // Dummy data for suppliers
  const dummySuppliers = [
    {
      _id: "1",
      name: "Supplier One",
      city: "City A",
      contact: "1234567890",
      openingDues: 1000,
    },
    {
      _id: "2",
      name: "Supplier Two",
      city: "City B",
      contact: "9876543210",
      openingDues: 1500,
    },
    {
      _id: "3",
      name: "Supplier Three",
      city: "City C",
      contact: "5555555555",
      openingDues: 2000,
    },
  ];

  const [supplier, setSupplier] = useState(dummySuppliers);
  const [filteredSuppliers, setFilteredSuppliers] = useState(dummySuppliers);
  const [cities, setCities] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [city, setCity] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [supplierToDelete, setSupplierToDelete] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [modeOfPayment, setPaymentMode] = useState("Cash");
  const [currentPaidAmount, setCurrentPaidAmount] = useState(0);
  const [transactionNo, setTransactionNo] = useState("");
  const [selectedSupplierForPayment, setSelectedSupplierForPayment] =
    useState(null);

  const handlePaymentClick = (supplier) => {
    setSelectedSupplierForPayment(supplier);
    setShowPaymentModal(true);
  };

  const closePaymentModal = () => {
    setShowPaymentModal(false);
    setPaymentMode("Cash");
    setTransactionNo("");
    setCurrentPaidAmount(0);
  };

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    if (!selectedSupplierForPayment) {
      toast.error("No supplier selected or invalid data.");
      return;
    }
    const supplierId = selectedSupplierForPayment._id;
    if (!supplierId) {
      toast.error("Supplier ID is missing.");
      return;
    }
    const paymentData = {
      currentPaidAmount,
      modeOfPayment,
      transactionNo,
      remainingAmount:
        selectedSupplierForPayment.openingDues - currentPaidAmount,
    };
    try {
      // Simulate payment update
      setSupplier((prevSuppliers) =>
        prevSuppliers.map((supplier) =>
          supplier._id === selectedSupplierForPayment._id
            ? {
                ...supplier,
                openingDues: supplier.openingDues - currentPaidAmount,
              }
            : supplier
        )
      );
      setFilteredSuppliers((prevFilteredSuppliers) =>
        prevFilteredSuppliers.map((supplier) =>
          supplier._id === selectedSupplierForPayment._id
            ? {
                ...supplier,
                openingDues: supplier.openingDues - currentPaidAmount,
              }
            : supplier
        )
      );
      toast.success("Payment updated successfully");
      closePaymentModal();
    } catch (error) {
      console.error("Error updating payment:", error);
      toast.error("Error updating payment. Please try again.");
    }
  };

  useEffect(() => {
    // Simulate fetching suppliers
    setSupplier(dummySuppliers);
    setFilteredSuppliers(dummySuppliers);
  }, []);

  useEffect(() => {
    if (supplier.length > 0) {
      const uniqueCities = [
        ...new Set(supplier.map((supplier) => supplier.city)),
      ];
      const sortedCities = uniqueCities.sort();
      setCities(sortedCities);
    }
  }, [supplier]);

  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    setCity(selectedCity);

    if (selectedCity === "All") {
      setFilteredSuppliers(supplier);
    } else {
      const filteredData = supplier.filter(
        (supplier) => supplier.city === selectedCity
      );
      setFilteredSuppliers(filteredData);
    }
  };

  const handleDeleteClick = (id) => {
    setSupplierToDelete(id);
    setShowModal(true);
  };

  const handleDelete = () => {
    setSupplier((prevSuppliers) =>
      prevSuppliers.filter((supplier) => supplier._id !== supplierToDelete)
    );
    setFilteredSuppliers((prevFilteredSuppliers) =>
      prevFilteredSuppliers.filter(
        (supplier) => supplier._id !== supplierToDelete
      )
    );
    toast.success("Supplier Deleted Successfully");
    setShowModal(false);
    setShowSuccessModal(true);
  };

  const handlePrintClick = (Supplier) => {
    selectedSupplier(Supplier);
    setShowPrintModal(true);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.length >= 2) {
      const filteredData = supplier.filter((supplier) =>
        supplier.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredSuppliers(filteredData);
    } else {
      setFilteredSuppliers(supplier);
    }
  };

  return (
    <div className="dashboard-root">
      <Header />
      <div className="main-d">
        <Sidebar />
        <div className="supplier-main">
          <ToastContainer />
          {/* Modals and table structure */}
          <div className="supplier-main-top">
            <div className="supplier-btn">
              <button className="new-supplier-btn">
                <Link to="/new-supplier">New Supplier</Link>
              </button>
              <button className="supplier-note-btn">
                <Link to="/SupplierNote">Note</Link>
              </button>
            </div>

            {/* supplier-input */}

            <div className="customer-input">
              <div className="city-search">
                <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
                  Search City
                </label>
                <select
                  className="select-options"
                  value={city}
                  onChange={handleCityChange}
                >
                  <option value="All">All</option>
                  {cities.length > 0 ? (
                    cities.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))
                  ) : (
                    <option value="">Loading cities...</option>
                  )}
                </select>
              </div>
              <div className="customer-search">
                <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
                  Search Customer
                </label>
                <input
                  placeholder="Search Customer"
                  className="customer-search-input"
                  value={searchQuery}
                  onChange={handleSearchChange}
                ></input>
              </div>
            </div>
          </div>

          {/* supplier-table */}

          <div className="supplier-table">
            <table>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Supplier Name</th>
                  <th>City</th>
                  <th>Mobile No.</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredSuppliers.map((supplier, index) => (
                  <tr key={supplier._id}>
                    <td>{index + 1}</td>
                    <td>{supplier.name}</td>
                    <td>{supplier.city}</td>
                    <td>{supplier.contact}</td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handlePaymentClick(supplier)}
                    >
                      ₹{supplier.openingDues}
                    </td>
                    <td>
                      <button style={{ color: "green", margin: "0 5px" }}>
                        <FaWhatsapp />
                      </button>
                      <button
                        style={{ color: "black", margin: "0 5px" }}
                        onClick={() => handlePrintClick(supplier)}
                      >
                        <FaPrint />
                      </button>
                      <button
                        style={{ color: "red", margin: "0 5px" }}
                        onClick={() => handleDeleteClick(supplier._id)}
                      >
                        <FaTrash />
                      </button>
                      <Link to={`/edit-supplier/${supplier._id}`}>
                        <button style={{ color: "#007bff", margin: "0 5px" }}>
                          <FaEdit />
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Supplier;
