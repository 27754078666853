import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./SideBar";
import Header from "./Header";
import { Modal, Button, Form } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import "./css/sales.css";

const Sales = () => {
  const [data, setData] = useState([
    {
      srNo: 1,
      date: "2024-12-10",
      quoteNo: "Q12345",
      partyName: "John Doe",
      materialName: "Material A",
      qty: 100,
      amount: 5000,
      vehicleNo: "XYZ 1234",
      InTransit: "Ready to deliver",
    },
    {
      srNo: 2,
      date: "2024-12-11",
      quoteNo: "Q12346",
      partyName: "Jane Smith",
      materialName: "Material B",
      qty: 200,
      amount: 10000,
      vehicleNo: "XYZ 5678",
      InTransit: "Ready to deliver",
    },
    {
      srNo: 3,
      date: "2024-12-12",
      quoteNo: "Q12347",
      partyName: "Alice Brown",
      materialName: "Material C",
      qty: 50,
      amount: 2500,
      vehicleNo: "XYZ 9101",
      InTransit: "Ready to deliver",
    },
    {
      srNo: 4,
      date: "2024-12-13",
      quoteNo: "Q12348",
      partyName: "Bob Green",
      materialName: "Material D",
      qty: 75,
      amount: 3750,
      vehicleNo: "XYZ 1122",
      InTransit: "Ready to deliver",
    },
  ]);

  const [showModal, setShowModal] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [vehicleNo, setVehicleNo] = useState("");
  const [handleReadyToDeliver, sethandleReadyToDeliver] = useState(false);
  const [place, setPlace] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [formData, setFormData] = useState({
    date: "",
    quoteNo: "",
    partyName: "",
    materialName: "",
    qty: "",
    amount: "",
    vehicleNo: "",
    InTransit: "",
  });

  const handleModal = () => setShowModal(!showModal);

  const filteredData = data.filter((row) => {
    const rowDate = new Date(row.date);
    const from = fromDate ? new Date(fromDate) : null;
    const to = toDate ? new Date(toDate) : null;

    return (!from || rowDate >= from) && (!to || rowDate <= to);
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAdd = () => {
    const newRow = {
      ...formData,
      srNo: data.length + 1,
    };
    setData([...data, newRow]);
    setFormData({
      date: "",
      quoteNo: "",
      partyName: "",
      materialName: "",
      qty: "",
      amount: "",
      vehicleNo: "",
      InTransit: "",
    });
    setShowModal(false);
  };

  const handleDelete = (index) => {
    const newData = data.filter((_, i) => i !== index);
    setData(newData);
  };

  const handleSave = () => {
    if (editIndex !== null) {
      const updatedData = [...data];
      updatedData[editIndex] = { ...formData, srNo: data[editIndex].srNo };
      setData(updatedData);
      setEditIndex(null);
    }

    setShowModal(false);
    setFormData({
      date: "",
      quoteNo: "",
      partyName: "",
      materialName: "",
      qty: "",
      amount: "",
      vehicleNo: "",
      InTransit: "",
    });
    setEditIndex(null);
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setFormData(data[index]);
    setShowModal(true);
  };

  // Function to handle submit of the modal
  const handleSubmit = () => {
    if (editIndex !== null) {
      const updatedData = [...data];
      updatedData[editIndex] = {
        ...updatedData[editIndex],
        vehicleNo,
        InTransit: "Ready to deliver",
        place,
      };
      setData(updatedData);
      setEditIndex(null); // Reset edit index
    }
    setShowModal(false); // Close the modal after submit
    setVehicleNo(""); // Clear vehicle number input
    setPlace(""); // Clear place input
  };

  const handleCancel = () => {
    // Close modal on cancel
    setShowModal(false);

    // Reset any other form data if needed
    setVehicleNo("");
    setPlace("");
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex flex-1">
        <Sidebar />
        <div className="sales-main">
          <div className="sales-top">
            <div className="print-bill-btn">
              <button className="print-btn">Print Bill</button>
            </div>
            <div className="sales-date-s">
              <div className="from-date">
                <p>From</p>
                <input
                  className="date-input-s"
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="to-date">
                <p>To</p>
                <input
                  className="date-input-s"
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
            </div>
            <div className="search-sales">
              <input
                className="search-by-quote"
                placeholder="Search Quote No."
              />
              <input
                className="search-by-party"
                placeholder="Search Customer Name"
              />
              <button className="search-btn">Search</button>
            </div>
          </div>

          <br />
          <br />
          <br />

          {/* Table */}
          <table className="sales-table">
            <thead>
              <tr>
                <th className="sr-no">Sr No.</th>
                <th className="sales-date">Date</th>
                <th className="sales-quote">Quote No.</th>
                <th className="sales-party">Party Name</th>
                <th className="sales-material">Material Name</th>
                <th className="sales-qty">Qty</th>
                <th className="sales-amount">Amount</th>
                <th className="sales-vehicle">Vehicle No.</th>
                <th className="sales-action">Action</th>
                <th className="sales-intransit">IN-Transit</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row, index) => (
                <tr key={index}>
                  <td className="sales-td">{row.srNo}</td>
                  <td className="sales-td">{row.date}</td>
                  <td className="sales-td">{row.quoteNo}</td>
                  <td className="sales-td">{row.partyName}</td>
                  <td className="sales-td">{row.materialName}</td>
                  <td className="sales-td">{row.qty}</td>
                  <td className="sales-td">{row.amount}</td>
                  <td className="sales-td">{row.vehicleNo}</td>
                  <td className="sales-td" style={{ gap: "10px" }}>
                    <button
                      style={{ color: "green", width: "25%" }}
                      className="action-edit-button"
                      onClick={() => handleEdit(index)}
                    >
                      <FaEdit />
                    </button>
                    <button
                      style={{ color: "red" }}
                      className="action-delete-button"
                      onClick={() => handleDelete(index)}
                    >
                      <FaTrash />
                    </button>
                  </td>
                  <td className="sales-td">
                    <button
                      className="action-ready-button"
                      // onClick={handleReadyToDeliver}
                      onClick={() => sethandleReadyToDeliver(true)}
                    >
                      Ready to Deliver
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {handleReadyToDeliver && (
            <div className="Deliver-overlay">
              <div className="Deliver-container">
                <h1>Ready to Delivery</h1>
                <div className="form-group-container">
                  <div className="form-group-Vehicle">
                    <label htmlFor="Vehicle-No">Vehicle No.</label>
                    <input
                      type="text"
                      value={vehicleNo}
                      onChange={(e) => setVehicleNo(e.target.value)}
                      placeholder="Vehicle Number"
                      // className={`rounded-input ${
                      //   stockError ? "error" : ""
                      // }`}
                      required
                    />

                    {/* Display vehicle capacity */}
                    {vehicleNo && (
                      <p className="vehicle-capacity">
                        {vehicleNo === "XYZ 1234"
                          ? "Capacity: 1 Brass"
                          : "Capacity: Unknown"}
                      </p>
                    )}
                  </div>

                  <div className="form-group-Place">
                    <label htmlFor="place">Place</label>
                    <select
                      id="place"
                      value={place}
                      onChange={(e) => setPlace(e.target.value)}
                      // className={`rounded-input ${
                      //   unitError ? "error" : ""
                      // }`}
                      required
                    >
                      <option value="">Select place</option>
                      <option value="Brass">Pune</option>
                      <option value="Ton">Mumbai</option>
                    </select>
                  </div>
                </div>

                <div className="button-group">
                  <button
                    type="button"
                    onClick={() => sethandleReadyToDeliver(false)}
                    className="Cancel-btn"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={handleSubmit} // Submit handler
                    className="Submit-btn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sales;
