import React, { useState } from "react";
import "./css/dashboard.css";
import Sidebar from "./SideBar";
import Header from "./Header";
import "./css/AddVendor.css";
import { Link } from "react-router-dom";
import { addCustomer } from "../services/customer";
import { FaAngleLeft } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

const AddVendor = () => {
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [city, setCity] = useState("");
  const [openingDues, setOpeningDues] = useState("");
  const [address, setAddress] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const vendorData = { name, contact, city, openingDues, address };
      const response = await addCustomer(vendorData);
      console.log("Vendor added successfully:", response);
    } catch (error) {
      console.error("Error adding vendor:", error);
    }
  };

  return (
    <div className="dashboard-root">
      <Header />
      <div className="main-d">
        <Sidebar />
        <div className="add-vendor-main">
          <div className="add-vendor-top">
            <button className="add-vendor-back">
              <Link to="/Maintenance">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <FaAngleLeft />
                  <p>Back</p>
                </div>
              </Link>
            </button>
            <button className="add-vendor-close">
              <Link to="/dashboard">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <p>Close</p>
                  <FaXmark />
                </div>
              </Link>
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="add-vendor-first-row">
              <div className="add-vendor-name">
                <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
                  Vendor Name
                </label>
                <input
                  className="vendor-first-row-input"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="add-vendor-phone">
                <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
                  Contact Number
                </label>
                <input
                  placeholder="Enter Number"
                  className="vendor-first-row-input"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                />
              </div>
              <div className="add-vendor-city">
                <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
                  City
                </label>
                <input
                  placeholder="Enter City"
                  className="vendor-first-row-input"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
            </div>
            <div className="add-vendor-second-row">
              <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
                Opening Dues
              </label>
              <input
                className="opening-dues-input"
                placeholder="Opening Dues"
                value={openingDues}
                onChange={(e) => setOpeningDues(e.target.value)}
              />
            </div>
            <div className="add-vendor-third-row">
              <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
                Vendor Address
              </label>
              <textarea
                className="add-vendor-address-input"
                placeholder="Enter Address"
                rows="4"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              ></textarea>
            </div>
            <div className="add-vendor-fourth-row">
              <button className="add-vendor-cancel">
                <Link to="/maintanance">Cancel</Link>
              </button>
              <button className="add-vendor-save" type="submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddVendor;
