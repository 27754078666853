import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative p-8 rounded-lg shadow-lg bg-white" style={{ width: '333px', height: '500px', borderRadius: '10px' }}>
        {React.cloneElement(children, { onClose })}
      </div>
    </div>
  );
};

export default Modal;
