import React from "react";
import Sidebar from "./SideBar";
import Header from "./Header";
import SalesPage from "./DashboardSales";
import RevenuePage from "./Dashboardrevenue";
import DashboardCount from "./dashboardCount";
import DBProductPurchase from "./DBProductPurchase";
import RatingsPages from "./RatingsPage";
import "./css/Dashboard1.css";
import "./css/dashboard.css";

const DashboardPage = () => {
  return (
    <div className="dashboard-root">
      <Header />
      <div className="main-d">
        <Sidebar />
        {/* Main Content */}
        <div className="dashboard-content">
          <div className="revenue-page-section1">
            <RevenuePage />
          </div>

          <div className="Product-Purchase-page-section">
            <DBProductPurchase />
          </div>

          <div className="Ratings-page-section">
            <RatingsPages />
          </div>

          <div className="dashboard-count-section">
            <DashboardCount />
          </div>

          <div className="sales-page-section">
            <SalesPage />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
