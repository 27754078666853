import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Header from './Header';
import Sidebar from './SideBar';
import './css/dashboard.css';
import './css/maintenance.css';
import './css/pagination.css';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { HiOutlineCheckCircle } from 'react-icons/hi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Maintenance = () => {
  const [vendors, setVendors] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('All');
  const [showAddVendorModal, setShowAddVendorModal] = useState(false);
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [mobile, setMobile] = useState('');
  const [duse, setDuse] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [vendorToDelete, setVendorToDelete] = useState(null);
  const [showEditVendorModal, setShowEditVendorModal] = useState(false);
  const [vendorToEdit, setVendorToEdit] = useState(null);

  const vendorPerPage = 10;

  // Dummy data
  const dummyVendors = [
    { _id: '6351', Name: 'Vendor 1', City: 'City 1', Mobile: '1234567890', Duse: 'Duse 1' },
    { _id: '6401', Name: 'Vendor 2', City: 'City 2', Mobile: '9876543210', Duse: 'Duse 2' },
    { _id: '6451', Name: 'Vendor 3', City: 'City 3', Mobile: '1122334455', Duse: 'Duse 3' },
  ];

  // Fetch vendors from the dummy data
  const fetchVendors = () => {
    setVendors(dummyVendors);
    setFilteredVendors(dummyVendors);
    const uniqueCities = [...new Set(dummyVendors.map((vendor) => vendor.City))];
    setCities(uniqueCities.sort());
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  // Filter vendors based on search query
  useEffect(() => {
    const filtered = vendors.filter((vendor) =>
      vendor.Name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredVendors(filtered);
    setCurrentPage(1); // Reset to the first page when searching
  }, [searchQuery, vendors]);

  // Add a new vendor
  const handleAdd = () => {
    if (!name || !city || !mobile || !duse) {
      toast.error('Please fill all the fields');
      return;
    }

    const newVendor = {
      _id: String(Math.floor(Math.random() * 10000)), // Generate a random ID
      Name: name,
      City: city,
      Mobile: mobile,
      Duse: duse,
    };

    setVendors([...vendors, newVendor]);
    setFilteredVendors([...vendors, newVendor]);
    toast.success('Vendor added successfully!');
    setName('');
    setCity('');
    setMobile('');
    setDuse('');
    setShowAddVendorModal(false);
  };

  // Delete a vendor
  const handleDeleteClick = (id) => {
    setVendorToDelete(id);
    setShowModal(true);
  };

  const handleDelete = () => {
    if (!vendorToDelete) return;

    const updatedVendors = vendors.filter((vendor) => vendor._id !== vendorToDelete);
    setVendors(updatedVendors);
    setFilteredVendors(updatedVendors);
    setShowModal(false);
    setVendorToDelete(null);
    toast.success('Vendor deleted successfully!');
  };

  // Edit a vendor
  const handleEditClick = (vendor) => {
    setVendorToEdit({
      _id: vendor._id,
      Name: vendor.Name,
      City: vendor.City,
      Mobile: vendor.Mobile,
      Duse: vendor.Duse,
    });
    setShowEditVendorModal(true);
  };

  const handleUpdateVendor = () => {
    if (!vendorToEdit) return;

    const updatedVendors = vendors.map((vendor) =>
      vendor._id === vendorToEdit._id ? vendorToEdit : vendor
    );

    setVendors(updatedVendors);
    setFilteredVendors(updatedVendors);
    setShowEditVendorModal(false);
    toast.success('Vendor Updated Successfully');
  };



  // Pagination logic
  const indexOfLastVendor = currentPage * vendorPerPage;
  const indexOfFirstVendor = indexOfLastVendor - vendorPerPage;
  const currentVendors = filteredVendors.slice(indexOfFirstVendor, indexOfLastVendor);
  const totalPages = Math.ceil(filteredVendors.length / vendorPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Render page numbers for pagination
  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (currentPage > 2) {
      pageNumbers.push(1);
      if (currentPage > 3) {
        pageNumbers.push('...');
      }
    }

    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (currentPage < totalPages - 1) {
      if (currentPage < totalPages - 2) {
        pageNumbers.push('...');
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`pagination-button ${currentPage === page ? 'active-page' : ''}`}
          onClick={() => paginate(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-ellipsis">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="dashboard-root">
      <Header />
      <div className="main-d">
        <Sidebar />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="maintenance-main">
          <div className="maintenance-main-top">
            <div className="maintenancetop-btn">
              <button
                className="new-maintenance-btn">
                <Link to="/add-vendor">New Vendor</Link>
              </button>
              <button className="maintenance-btn"><Link to="/Show-Maintenance">Show Maintenance</Link></button>
            </div>
            <div className="maintenancetop-input">
              <div className="maintenanceselect-input">
                <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Select City</label>
                <select
                  className="select-options"
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                >
                  <option value="All">All</option>
                  {cities.length > 0 ? (
                    cities.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))
                  ) : (
                    <option value="">Loading cities...</option>
                  )}
                </select>
              </div>
              <div className="maintenancesearch-input">
                <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Search Customer</label>
                <input
                  className="maintenance-search-input"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search by customer name"
                />
              </div>
            </div>
          </div>
          <div className="maintenance-table">
            <div className="overflow-x-auto" style={{ borderRadius: '8px' }}>
              <table className="maintenance-table-data">
                <thead>
                  <tr className="maintenance-table-heading">
                    <th>Sr No.</th>
                    <th>Vendor</th>
                    <th>City</th>
                    <th>Mobile No</th>
                    <th>Duse</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="maintenance-data">
                  {currentVendors.map((vendor, index) => (
                    <tr key={vendor._id || index} className="maintenance-data-main">
                      <td>{(currentPage - 1) * vendorPerPage + index + 1}</td>
                      <td>{vendor.Name}</td>
                      <td>{vendor.City}</td>
                      <td>{vendor.Mobile}</td>
                      <td>{vendor.Duse}</td>
                      <td>
                        <button
                          style={{ color: '#007bff', margin: '0 5px' }}
                          onClick={() => handleEditClick(vendor)}
                        >
                          <FaEdit />
                        </button>
                        <button
                          style={{ color: 'red', margin: '0 5px' }}
                          onClick={() => handleDeleteClick(vendor._id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="pagination">
            <button
              className="pagination-button"
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              {'<'}
            </button>
            {renderPageNumbers()}
            <button
              className="pagination-button"
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              {'>'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;