import { FaUserCircle, FaSignOutAlt } from 'react-icons/fa';
import './css/header.css';
import React, { useState , useEffect } from 'react';
import "./css/model.css";
import { FaCircleXmark, FaMarker, FaRegCircleUser, FaBuildingColumns } from "react-icons/fa6";
import { getUsername } from '../services/header';
const Header = () => {
  const handleLogout = () => {
    // Handle logout logic here
    console.log("User logged out");
  };
  const [username, setUsername] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showBankModal, setShowBankModel] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const fetchedUsername = await getUsername();
        setUsername(fetchedUsername);  // Set the fetched username
      } catch (error) {
        console.error("There was an error fetching the username:", error);
        setUsername('Error fetching username');
      }
    };

    fetchUsername();
  }, []);


  const handleClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClose = () => {
    setShowModal(false);
    setShowBankModel(false);
  };

  const handleBankClick = () => {
    setShowModal(false);
    setShowBankModel(true);
  };

  const handleProfileClick = () => {
    setShowModal(true);
    setShowBankModel(false);
    setShowDropdown(false); // Close dropdown when profile is clicked
  };

  return (
    <nav className="nav-h">
      {showModal && (
        <div className="modal">
          <div className='profile-popup' style={{ display: 'flex', height: '100%', width: '100%', background: '#fff', borderRadius: '10px' }}>
            <div className='profile-left'>
              <FaRegCircleUser className='user-profile' />
              <button className='edit-btn'>Edit<FaMarker style={{ marginLeft: '5px' }} /></button>
              <button className='profile-btn'>
                <div className='profile-btn-div'>
                  <FaRegCircleUser style={{ height: '20px', width: '20px', color: '#B49545' }} />
                  <p style={{ color: '#B49545' }}>Name of Stone Crusher</p>
                </div>
              </button>
              <button className='bank-btn' onClick={handleBankClick}>
                <div className='bank-div-btn'>
                  <FaBuildingColumns />
                  <p>Bank Details</p>
                </div>
              </button>
            </div>
            <div className='profile-right' style={{ width: '65%' }}>
              <div className='right-top'>
                <FaCircleXmark onClick={handleClose} style={{ marginLeft: '92%', marginTop: '5%', width: '24px', height: '24px', color: 'gray', cursor: 'pointer' }} />
                <h1 className='profile-heading'>My Profile</h1>
                <form className='profile-form'>
                  <div className='first-profile-input'>
                    <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Name of Stone Crusher</label>
                    <input className='profile-input' />
                  </div>
                  <div className='profile-secont-input' style={{ display: 'flex', marginTop: '10px' }}>
                    <div className='input-first'>
                      <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Contact</label>
                      <input className='profile-input' />
                    </div>
                    <div className='input-second'>
                      <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Email I'd</label>
                      <input className='profile-input' />
                    </div>
                  </div>
                  <div className='first-profile-input'>
                    <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Stone Crusher Documents</label>
                    <input className='profile-input' />
                  </div>
                  <div className='first-profile-input'>
                    <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Owner Name</label>
                    <input className='profile-input' />
                  </div>
                  <div className='first-profile-input'>
                    <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Owner Contact</label>
                    <input className='profile-input' />
                  </div>
                  <div className='first-profile-input'>
                    <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Address</label>
                    <input className='profile-input' />
                  </div>
                  <div className='last-div'>
                    <button className='logout-btn'>Logout</button>
                    <button className='update-btn'>Update</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {showBankModal && (
        <div className="modal">
          <div className='profile-popup' style={{ display: 'flex', height: '100%', width: '100%', background: '#fff', borderRadius: '10px' }}>
            <div className='profile-left'>
              <FaRegCircleUser className='user-profile' />
              <button className='edit-btn'>Edit<FaMarker style={{ marginLeft: '5px' }} /></button>
              <button className='profile-btn-non'>
                <div className='profile-btn-div-non' onClick={handleProfileClick} style={{ cursor: 'pointer' }}>
                  <FaRegCircleUser />
                  <p>Name of Stone Crusher</p>
                </div>
              </button>
              <button className='bank-btn-active'>
                <div className='bank-div-btn-active'>
                  <FaBuildingColumns style={{ height: '20px', width: '20px', color: '#B49545' }} />
                  <p style={{ color: '#B49545' }}>Bank Details</p>
                </div>
              </button>
            </div>
            <div className='profile-right' style={{ width: '65%' }}>
              <div className='right-top'>
                <FaCircleXmark onClick={handleClose} style={{ marginLeft: '92%', marginTop: '5%', width: '24px', height: '24px', color: 'gray', cursor: 'pointer' }} />
                <h1 className='profile-heading'>Bank Details</h1>
                <form className='profile-form'>
                  <div className='first-profile-input'>
                    <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Account Number</label>
                    <input className='profile-input-2' placeholder='Enter Number' />
                  </div>
                  <div className='first-profile-input'>
                    <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>IFSC Code</label>
                    <input className='profile-input-2' placeholder='IFSC Code' />
                  </div>
                  <div className='first-profile-input'>
                    <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Account Holder Name</label>
                    <input className='profile-input-2' placeholder='Enter Name' />
                  </div>
                  <div className='first-profile-input'>
                    <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Bank Name</label>
                    <input className='profile-input-2' placeholder='Enter Bank Name' />
                  </div>
                  <div className='first-profile-input'>
                    <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Upload OR Image</label>
                    <input className='profile-input-3' type='file' />
                  </div>
                  <div className='last-div'>
                    <button className='logout-btn'>Logout</button>
                    <button className='update-btn'>Update</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="header-name">InnovativSoft Stone Crusher ERP</div>
      <div className="header-div">
        <div className="username-img" onClick={handleClick}>
          <FaUserCircle size={24} />
          <span>{username || 'Loading...'}</span>  {/* Display 'Loading...' until the username is fetched */}
          {showDropdown && (
            <div className="dropdown-menu">
              <button className="dropdown-item" onClick={handleProfileClick}>
                <FaRegCircleUser style={{ marginRight: '8px' }} />
                Profile
              </button>
              <button className="dropdown-item" onClick={handleLogout}>
                <FaSignOutAlt style={{ marginRight: '8px' }} />
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;