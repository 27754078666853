import React, { useEffect, useState, useRef } from 'react'
import './css/dashboard.css'
import './css/Customer.css'
import './css/pagination.css'
import Header from './Header'
import Sidebar from './SideBar'
import { Link } from 'react-router-dom'
import { getCustomers, deleteCustomer, updatePayment } from '../services/customer'
import { FaTrash, FaEdit, FaWhatsapp, FaPrint } from 'react-icons/fa'
import { HiOutlineCheckCircle } from "react-icons/hi"
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import jsPDF from 'jspdf'


const Customer = () => {
  const [customer, setCustomer] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [cities, setCities] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [city, setCity] = useState('');
  const [showModal, setShowModal] = useState(false); 
  const [showSuccessModal, setShowSuccessModal] = useState(false);  
  const [customerToDelete, setCustomerToDelete] = useState(null);  
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showPrintModal, setShowPrintModal] = useState(false);

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [modeOfPayment, setPaymentMode] = useState('Cash');
  const [currentPaidAmount, setCurrentPaidAmount] = useState(0);
  const [transactionNo, setTransactionNo] = useState('');
  const [selectedCustomerForPayment, setSelectedCustomerForPayment] = useState(null);

  const customerPerPage = 10;

  const handlePaymentClick = (customer) => {
    setSelectedCustomerForPayment(customer);
    console.log("Selected customer:", customer); 
    setShowPaymentModal(true);
  };
  

  const closePaymentModal = () => {
    setShowPaymentModal(false);
    setPaymentMode('Cash');
    setTransactionNo('');
    setCurrentPaidAmount(0);
  };

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
  
    if (!selectedCustomerForPayment || typeof selectedCustomerForPayment !== 'object') {
      console.log("Selected customer for payment modal:", selectedCustomerForPayment);
      toast.error("No customer selected or invalid data.");
      return;
    }
  
    console.log("Selected customer object for payment:", selectedCustomerForPayment);
  
    const customerId = selectedCustomerForPayment._id; 
    if (!customerId) {
      toast.error("Customer ID is missing.");
      return;
    }
  
    const paymentData = {
      currentPaidAmount: currentPaidAmount,  
      modeOfPayment,
      transactionNo,
      remainingAmount: selectedCustomerForPayment.openingDues - currentPaidAmount,
    };
  
    try {
      const response = await updatePayment(customerId, paymentData);
      console.log("Payment update response:", response);
  
      setCustomer((prevCustomers) =>
        prevCustomers.map((customer) =>
          customer._id === selectedCustomerForPayment._id
            ? {
                ...customer,
                openingDues: customer.openingDues - currentPaidAmount, 
              }
            : customer
        )
      );
  
      setFilteredCustomers((prevFilteredCustomers) =>
        prevFilteredCustomers.map((customer) =>
          customer._id === selectedCustomerForPayment._id
            ? {
                ...customer,
                openingDues: customer.openingDues - currentPaidAmount,
              }
            : customer
        )
      );
      toast.success("Payment updated successfully");
      closePaymentModal(); 
    } catch (error) {
      console.error("Error updating payment:", error);
      toast.error("Error updating payment. Please try again.");
    }
  };
  
  
  
  
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await getCustomers();
        console.log("Fetched customers:", response.customers); 
        setCustomer(response.customers);
        setFilteredCustomers(response.customers);
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };
    fetchCustomers();
  }, []);
  

  const printRef = useRef();

  const handlePrintClick = (customer) => {
    setSelectedCustomer(customer);
    setShowPrintModal(true);
  };

  const handlePdfDownload = () => {
    const doc = new jsPDF();
    doc.html(printRef.current, {
      callback: function (doc) {
        doc.save('Customer_Details.pdf');
      },
      x: 10,
      y: 10,
    });
  };
  

  
  const handleSuccessModalClose = () => {
    setShowSuccessModal(false); 
  };  

  const handleDeleteClick = (id) => {
    setCustomerToDelete(id);  
    setShowModal(true);  
  };
  
  const handleModalClose = () => {
    setShowModal(false); 
    setCustomerToDelete(null);
  };
  


  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  useEffect(() =>{
    const fetchCustomers = async () => {
      try {
        const response = await getCustomers();
        setCustomer(response.customers);
        setFilteredCustomers(response.customers);
      } catch (error) {
        console.error("Error fetching customers:", error)
      } 
    };
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (customer.length > 0) {
      const uniqueCities = [...new Set(customer.map((customer) => customer.city))];
      const sortedCities = uniqueCities.sort();
      setCities(sortedCities);
    }
  }, [customer]);

  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    setCity(selectedCity);

    if (selectedCity === 'All') {
      setFilteredCustomers(customer); 
    } else {
      const filteredData = customer.filter((customer) => customer.city === selectedCity);
      setFilteredCustomers(filteredData); 
    }
    setCurrentPage(1); 
  };

  const handleDelete = async () => {
    try {
      await deleteCustomer(customerToDelete);
      setCustomer((prevCustomers) =>
        prevCustomers.filter((customer) => customer._id !== customerToDelete)
      );
      setFilteredCustomers((prevFilteredCustomers) =>
        prevFilteredCustomers.filter((customer) => customer._id !== customerToDelete)
      );
      toast.success('Customer Deleted Successfully')
      setShowModal(false); 
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Error deleteting customer:', error);
      toast.error('Error deleting customer. Please try again.')
    }
    setShowModal(false);
    setShowModal(false); 
  }

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.length >= 2) {
      const filteredData = customer.filter((customer) =>
        customer.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredCustomers(filteredData);
    } else {
      setFilteredCustomers(customer);
    }
    setCurrentPage(1); 
  };

  const indexOfLastCustomer = currentPage * customerPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - customerPerPage;
  const currentCustomers = filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);

  const totalPages = Math.ceil(filteredCustomers.length / customerPerPage)

  const renderPageNumbers = () => {
    
    const pageNumbers = [];
  
    if (currentPage > 2) {
      pageNumbers.push(1);
      if (currentPage > 3) {
        pageNumbers.push("...");
      }
    }

    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 1);
  
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    if (currentPage < totalPages - 1) {
      if (currentPage < totalPages - 2) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages);
    }
  
    return pageNumbers.map((page, index) =>
      typeof page === "number" ? (
        <button
          key={index}
          className={`pagination-button ${
            currentPage === page ? "active-page" : ""
          }`}
          onClick={() => paginate(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-ellipsis">
          {page}
        </span>
      )
    );
  };



  return (
    <div className="dashboard-root">
      <Header />
      <div className="main-d">
          <Sidebar />
          <div className='customer-main'>
          <ToastContainer 
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
            {showModal && (
              <div className="delete-modal">
                <div className="modal-content">
                  <div className='model-main-content'>
                    <div className='model-image'>
                      <HiOutlineCheckCircle style={{width:'55px', height:'55px', color: 'gray', marginTop:'5px', marginLeft:'5px'}}/>
                    </div>
                    <div className='model-text'>
                      <h1 style={{fontSize:'x-large', fontWeight:'bold', color:'#007bff'}}>Sure you want to delete?</h1>
                      <p>Are you sure you want to delete this customer details?</p>
                    </div>
                  </div>
                  <div className='model-btn'>
                    <button onClick={handleModalClose} className="cancel-btn">No, Cancel</button>
                    <button onClick={handleDelete} className="confirm-btn">Yes, Confirm</button>
                  </div>
                </div>
              </div>
            )}
            {showSuccessModal && (
              <div className="success-modal">
                <div className="modal-content">
                  <HiOutlineCheckCircle style={{width:'80px', height:'80px', color: 'green', marginTop:'5px', marginLeft:'160px'}}/>
                  <h1 style={{fontSize:'x-large', fontWeight: 'bold', marginTop:'25px', color:"#007bff"}}>Deleted Successfully!</h1>
                  <p>Selected customer data is deleted from the database<br/>Successfully.</p>
                  <button onClick={handleSuccessModalClose} className="close-btn">
                    Done
                  </button>
                </div>
              </div>
            )}
            {showPrintModal && (
              <div className="print-modal">
                <div className="modal-content">
                  <h2>Print Preview</h2>
                  <div ref={printRef}>
                    <h1>Customer Details</h1>
                    <p><strong>Name:</strong> {selectedCustomer?.name}</p>
                    <p><strong>City:</strong> {selectedCustomer?.city}</p>
                    <p><strong>Mobile:</strong> {selectedCustomer?.contact}</p>
                    <p><strong>Amount:</strong> {selectedCustomer?.openingDues}</p>
                  </div>
                  <div className="modal-actions">
                    <button onClick={handlePdfDownload}>Download PDF</button>
                    <button onClick={() => setShowPrintModal(false)}>Close</button>
                  </div>
                </div>
              </div>
            )}
            {showPaymentModal && (
              <div className="payment-modal">
                <div className="modal-content">
                  <h2 style={{fontSize:'x-large', fontWeight:'bolder', color:'#007bff'}}>Update Payment</h2>
                  <form onSubmit={handlePaymentSubmit}> 
                    <div className='payment-model-form' style={{marginTop: '10%'}}>
                      <div className='first-row-payment-model' style={{display:'flex', height:'60px', width: '100%'}}>
                        <div className='first-left-payment-model' style={{width:'48%'}}>
                          <label style={{textAlign: 'start'}}>Mode of Payment</label>
                          <select value={modeOfPayment} onChange={(e) => setPaymentMode(e.target.value)} style={{width:'100%', height: '40px', paddingLeft: '15px', borderRadius: '5px', background: '#E5E4E2'}}>
                            <option value="Cash">Cash</option>
                            <option value="UPI">UPI</option>
                            <option value="NEFT">NEFT</option>
                            <option value="RTGS">RTGS</option>
                          </select>
                        </div>
                        <div className='first-right-payment-model' style={{width: '48%', marginLeft: '4%'}}>
                        {modeOfPayment !== 'Cash' && (
                            <div>
                              <label style={{textAlign:'start'}}>Transaction No.</label>
                              <input
                                type="text"
                                value={transactionNo}
                                onChange={(e) => setTransactionNo(e.target.value)}
                                required
                                style={{width: '100%', height: '40px', borderRadius: '5px', background: '#E5E4E2'}}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='second-row-payment-model' style={{display:'flex', marginTop: '5%', textAlign: 'start', width: '100%'}}>
                        <div className='second-left-payment-model' style={{width: '48%', height: '60px'}}>
                          <label>Opening Dues</label>
                          <input type="text" value={selectedCustomerForPayment?.openingDues || 0} readOnly style={{width: '100%', height: '40px', borderRadius: '5px', paddingLeft: '10px', background: '#E5E4E2'}} />
                        </div>
                        <div className='second-right-payment-model' style={{width: '48%', marginLeft:'4%'}}>
                          <label>Current Paid Amount</label>
                          <input
                            type="text"
                            value={currentPaidAmount}
                            onChange={(e) => setCurrentPaidAmount(Number(e.target.value))}
                            required
                            style={{height: '40px', borderRadius: '5px', paddingLeft:'10px', width: '100%', background: '#E5E4E2'}}
                          />
                        </div>
                      </div>
                      <div className='payment-model-btn' style={{display:'flex', height: '60px', marginTop: '5%'}}>
                        <div className="modal-actions" style={{width: '100%'}}>
                          <button onClick={closePaymentModal} style={{width: '46%', background: '#E5E4E2', height:'40px', borderRadius: '10px', fontSize:'large', fontWeight: 'bold'}}>Cancel</button>
                          <button type="submit" style={{width: '46%', height:'40px', borderRadius: '10px', fontSize:'large', fontWeight: 'bold', marginLeft: '8%', background:'#007bff', color:'#fff'}}>Save</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
            <div className='customer-main-top'>
              <div className='customer-btn'>
                <button className='new-customer-btn'>
                  <Link to="/new-customer">New Customer</Link>
                </button>
                <button className='customer-note-btn'>
                  <Link to="/note">Note</Link>
                </button>
              </div>
              <div className='customer-input'>
                <div className='city-search'>
                  <label style={{fontWeight:'bold', marginBottom:'5px'}}>Search City</label>
                  <select 
                    className="select-options"
                    value={city}
                    onChange={handleCityChange}
                  >
                    <option value="All">All</option>
                    {cities.length > 0 ? (
                      cities.map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))
                    ) : (
                      <option value="">Loading cities...</option> 
                    )}
                  </select>
                </div>
                <div className='customer-search'>
                  <label style={{fontWeight:'bold', marginBottom:'5px'}}>Search Customer</label>
                  <input 
                    placeholder='Search Customer'
                    className='customer-search-input'
                    value={searchQuery}
                    onChange={handleSearchChange}  
                  ></input>
                </div>
              </div>
            </div>
            <div className='customer-table'>
              <div className='overflow-x-auto' style={{borderRadius: '8px'}}>
                <table className='customer-table-data'>
                  <thead>
                    <tr className='table-heading'>
                      <th>Sr No.</th>
                      <th>Customer Name</th>
                      <th>City</th>
                      <th>Mobile No.</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className='customer-data'>
                    {currentCustomers.map((customer, index) => {
                      return (
                        <tr key={customer._id} className='customer-data-main'>
                          <td>{(currentPage - 1) * customerPerPage + index + 1}</td>
                          <td>{customer.name}</td>
                          <td>{customer.city}</td>
                          <td>{customer.contact}</td>
                          <td style={{cursor: 'pointer'}} onClick={() => handlePaymentClick(customer)} >
                              ₹{customer.openingDues}
                          </td>
                          <td>
                            <button style={{color:'green', margin: '0 5px'}}><FaWhatsapp/></button>
                            <button style={{color:'gray', margin: '0 5px'}} onClick={() => handlePrintClick(customer)}><FaPrint/></button>
                            <button style={{color:'red', margin: '0 5px'}} onClick={() => handleDeleteClick(customer._id)}><FaTrash/></button>
                            <Link to={`/edit-customer/${customer._id}`}>
                              <button style={{color:'#007bff', margin: '0 5px'}}><FaEdit/></button>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pagination">
              <button
                className="pagination-button"
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
              {renderPageNumbers()}
              <button
                className="pagination-button"
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                {">"}
              </button>
            </div>
          </div>
      </div>
    </div>
  )
}
export default Customer




