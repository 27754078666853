import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { sendOtp, verifyOtp, updateCredential } from '../services/forgotService';


const Forgot = ({onClose}) => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [maskedUsername, setMaskedUsername] = useState('');
  const [credentialType, setCredentialType] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [timer, setTimer] = useState(120); 
  const [otpTimeout, setOtpTimeout] = useState(false); 
  const [showSecondModal, setShowSecondModal] = useState(false);
  

  const handleSelectChange = (e) => {
    setCredentialType(e.target.value);
  };

  const handleSendOtp = async () => {
    if (!email) {
      toast.error('Please enter your email.');
      return;
    }
    try {
      const data = await sendOtp(email);
      console.log("Otp Sent", data)
      toast.success('OTP sent to your email.');
      setOtpSent(true);
      setOtpTimeout(false); 
      setTimer(120); 
    } catch (error) {
      console.error(error);
      toast.error('Failed to send OTP. Please try again.');
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      toast.error('Please enter the OTP.');
      return;
    }
    try {
      const data = await verifyOtp(email, otp);
      console.log("Otp Verified", data)
      toast.success('OTP verified successfully.');
      setOtpVerified(true);
      if (credentialType === 'username') {
        const username = data.username;
        const masked = username
          .split('')
          .map((char, idx) => (idx % 2 === 0 ? char : '*'))
          .join('');
        setMaskedUsername(masked);
      }
      setShowSecondModal(true);
    } catch (error) {
      console.error(error);
      toast.error('Invalid OTP or OTP expired.');
    }
  };

  const handleUpdatePassword = async () => {
    if (!newPassword) {
      toast.error(`Please enter your new password.`);
      return;
    }
    try {
      const data = await updateCredential(email, newPassword, 'password');
      console.log("Updated Password", data)
      toast.success("Password updated successfully.");
      setShowSecondModal(false); 
    } catch (error) {
      console.error(error);
      toast.error('Error updating password. Please try again later.');
    }
  };

  useEffect(() => {
    let interval;
    if (otpSent && timer > 0 && !otpVerified) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setOtpTimeout(true); 
    }

    return () => clearInterval(interval);
  }, [otpSent, timer, otpVerified]);

  const handleResendOtp = () => {
    setOtpSent(false); 
    setOtpTimeout(false); 
    setTimer(120); 
    handleSendOtp(); 
  };

  return (
    <div className="text-center w-full">
      <button
        className="" style={{marginLeft:'90%', fontWeight:'bolder'}}
        onClick={onClose}
      >
        X
      </button>
      <h1 className="text-black text-2xl font-bold font-Poppins mb-2">
        <b>Innovativ<span style={{ color: 'rgb(234, 209, 0, 1)' }}>Soft</span></b>
      </h1>
      <h2 className="text-2xl font-bold mb-2">Forgot</h2>
      <form className="py-16">
        {!showSecondModal ? (
          <>
            <div className="mb-32">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 text-left"
                style={{ marginTop: '-3rem' }}
              >
                Select
              </label>
              <select
                value={credentialType}
                onChange={handleSelectChange}
                className="shadow appearance-none border rounded w-64 h-10 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="">Choose Username/Password</option>
                <option value="username">Username</option>
                <option value="password">Password</option>
              </select>
            </div>
            <div className="mb-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 text-left"
                style={{ marginTop: '-6.5rem' }}
              >
                Email
              </label>
              <input
                type="email"
                className="shadow appearance-none border rounded w-64 h-10 py-2 px-3 text-gray-700 mb-2 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Type Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="text-center mb-4">
              {!otpSent ? (
                <button
                  type="button"
                  className="bg-gradient-to-r from-blue-500 via-purple-600 to-pink-500 hover:from-blue-500 hover:to-purple-500 text-white font-bold py-2 px-6 rounded-full focus:outline-none"
                  onClick={handleSendOtp}
                >
                  Get OTP
                </button>
              ) : (
                <div>
                  <input
                    type="text"
                    className="shadow appearance-none border rounded w-64 h-10 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                  <button
                    type="button"
                    className="bg-gradient-to-r from-blue-500 via-purple-600 to-pink-500 hover:from-blue-500 hover:to-purple-500 text-white font-bold py-2 px-0 rounded-full focus:outline-none"
                    style={{width:'45%', marginRight:'2.5%', fontSize:'15px', marginLeft:'2.5%'}}
                    onClick={handleVerifyOtp}
                  >
                    Verify OTP
                  </button>
                  {otpTimeout && (
                    <button
                      type="button"
                      className="mt-4 bg-gradient-to-r from-blue-500 via-purple-600 to-pink-500 hover:from-blue-500 hover:to-purple-500 text-white font-bold py-2 px-0 rounded-full focus:outline-none"
                      style={{width:'45%', marginRight:'2.5%', fontSize:'15px', marginLeft:'2.5%'}}
                      onClick={handleResendOtp}
                    >
                      Resend OTP
                    </button>
                  )}
                  <div className="mt-4 text-sm text-gray-500">
                    {timer > 0 ? (
                      <span>Time left: {Math.floor(timer / 60)}:{timer % 60}</span>
                    ) : (
                      <span>OTP expired. You can request a new OTP.</span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
         ) : credentialType === 'username' ? (
          <div>
            <h3 className="text-xl font-bold mb-4">Your Username</h3>
            <p className="text-gray-700 text-lg">{maskedUsername}</p>
          </div>
        ) : (
          <div>
            <h3 className="text-xl font-bold mb-4">Update Password</h3>
            <input
              type="password"
              className="shadow appearance-none border rounded w-64 h-10 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <button
              type="button"
              className="bg-gradient-to-r from-blue-500 via-purple-600 to-pink-500 hover:from-blue-500 hover:to-purple-500 text-white font-bold py-2 px-6 rounded-full focus:outline-none"
              onClick={handleUpdatePassword}
            >
              Update Password
            </button>
          </div>
        )}
      </form>
      <ToastContainer 
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Forgot;
