import React, {useState} from 'react'
import Header from './Header';
import Sidebar from './SideBar';
import './css/Add.css'
import { addEmployee } from '../services/add';

const Home = () => {
    const [showEmergencyContact, setShowEmergencyContact] = useState(false);
    const [showBankInfo, setShowBankInfo] = useState(false);
    const [showReportingPerson, setShowReportingPerson] = useState(false);
    const [dob, setDob] = useState('')
    const [age, setAge] = useState('')
    const [hasDrivingLicense, setHasDrivingLicense ] = useState();
    const [drivingLicenseImage, setDrivingLicenseImage] = useState(null);

    const [name, setName] = useState('')
    const [contact, setContact] = useState('')
    const [email, setEmail] = useState('')
    const [designation, setDesignation] = useState('')
    const [dailywages, setDailywages] = useState('')
    const [appoinmentdate, setAppoinmentdate] = useState('')
    const [rname, setRname] = useState('')
    const [relation, setRelation] = useState('')
    const [rcontact, setRcontact] = useState('')
    const [bank, setBankname] = useState('')
    const [accno, setAccno] = useState('')
    const [ifsc, setIfsc] = useState('')
    const [rperson, setRperson] = useState('')


    const calculateAge = (dob) => {
      const birthDate = new Date(dob);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      setAge(age);
    };

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setDrivingLicenseImage(file);
      if (file) {
        console.log('Selected file:', file);
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      try {
        const employeeData = { name, 
            email, 
            contact, 
            designation, 
            dailywages, 
            appoinmentdate,
            rname, 
            rcontact, 
            relation, 
            bank, 
            accno, 
            ifsc, 
            rperson };
        const response = await addEmployee(employeeData);
        console.log('Customer added successfully:', response);
        } catch (error) {
            console.error('Error adding customer:', error)
        }
    };

  return (
    <div className="dashboard-root">
      <Header />
        <div className="main-d">
          <Sidebar />
    <div className='main-add-employee'>
      <form className='first-section' onSubmit={handleSubmit}>
        <div className='first-row'>
          <h1 className='heading'>Add Employee</h1>
          <button className='close-btn'>Close</button>
        </div>
        <div className='second-row'>
          <div className='second-input-f'>
            <label style={{fontWeight:'bold', marginBottom:'5px'}}>Name of Employee</label>
            <input 
              className='input' 
              type='text' 
              placeholder='Name of Employee'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className='second-input-s'>
            <label style={{fontWeight:'bold', marginBottom:'5px'}}>Contact No.</label>
            <input 
              className='input' 
              type='text' 
              placeholder='Contact No.'
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </div>
          <div className='second-input-t'>
            <label style={{fontWeight:'bold', marginBottom:'5px'}}>Email</label>
            <input 
              className='input' 
              type="email" 
              placeholder='Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className='third-row'>
          <div className='third-input-f'>
            <label style={{fontWeight:'bold', marginBottom:'5px'}}>Designation</label>
            <input 
              className='input-2' 
              type='text' 
              placeholder='Designation'
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
            />
          </div>
         <div className='third-input-s'>
            <label style={{fontWeight:'bold', marginBottom:'5px'}}>Daily Wages</label>
            <input 
              className='input-d' 
              type='text' 
              placeholder='Daily Wages'
              value={dailywages}
              onChange={(e) => setDailywages(e.target.value)}
            />
         </div>
         <div className='third-input-t'>
            <label style={{fontWeight:'bold', marginBottom:'5px'}}>Appoinment Date</label>
            <input 
              className='input-a' 
              type="date" 
              placeholder='Appoinment Date'
              value={appoinmentdate}
              onChange={(e) => setAppoinmentdate(e.target.value)}
            />
         </div>
          <div className='third-input-frth'>
            <label style={{fontWeight:'bold', marginBottom:'5px'}}>Date of Birth</label>
            <input 
              className='input-db' 
              type='date' 
              placeholder='Date of Birth'
              value={dob}
              onChange={(e) => {
                  setDob(e.target.value);
                  calculateAge(e.target.value);
              }}
            />
            {age && <p className="text-sm text-gray-600 text-xs">Age: {age} years</p>}
          </div>
          <div className='third-input-fth'>
            <label className='third-text' style={{fontWeight:'bold', marginBottom:'5px'}}>Driving License</label>
            <div className='radio-input'>
              <div className='first-input'>
                <input 
                  type='radio'
                  name="driving_license" 
                  value="yes" 
                  className="mr-2" 
                  checked={hasDrivingLicense === 'yes'}
                  onChange={() => setHasDrivingLicense('yes')}
                />
                <p>Yes</p>
              </div>
              <div className='second-input'>
                <input 
                  type='radio'
                  name="driving_license" 
                  value="no" 
                  className="mr-2" 
                  checked={hasDrivingLicense === 'no'}
                  onChange={() => setHasDrivingLicense('no')}
                />
                <p>No</p>
              </div>
              {hasDrivingLicense === 'yes' && (
                <div className="driving-license-image-div">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="image-input"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='model-button'>
            <button
              className='model-emergency-btn'
              onClick={() => setShowEmergencyContact(!showEmergencyContact)}
            >
              Emergency Contract Info
            </button>
            <button
              className='model-bank-btn'
              onClick={() => setShowBankInfo(!showBankInfo)}
            >
              Bank Account Info
            </button>
            <button
              className='model-reporting-btn'
              onClick={() => setShowReportingPerson(!showReportingPerson)}
            >
              Reporting Person
            </button>
          </div>
          <div className='model-div'>
            <div className='first-model'>
              {
                showEmergencyContact && (
                  <div className='show-emergency-contact'>
                    <div className='emergency-first-row'>
                      <h1 className='e-h'>Add Emergency Contract Info</h1>
                    </div>
                    <div className='emergency-second-row'>
                      <div className='emergency-first-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Name of Relative</label>
                        <input 
                          className='input' 
                          type='text' 
                          placeholder='Relative Name'
                          value={rname}
                          onChange={(e) => setRname(e.target.value)}
                        />
                      </div>
                      <div className='emergency-second-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Relation</label>
                        <input 
                          className='input' 
                          type='text' 
                          placeholder='Relationship'
                          value={relation}
                          onChange={(e) => setRelation(e.target.value)}
                        />
                      </div>
                      <div className='emergency-third-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Contact No.</label>
                        <input 
                          className='input' 
                          type='text' 
                          placeholder='Contact No.'
                          value={rcontact}
                          onChange={(e) => setRcontact(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                )
              }
              {
                showBankInfo && (
                  <div className='show-account-info'>
                    <div className='bank-first-row'>
                      <h1 className='a-h'> Add Bank Account Info</h1>
                    </div>
                    <div className='bank-second-row'>
                      <div className='bank-second-input-f'>
                        <p>Bank Name</p>
                        <input 
                          className='input' 
                          type='text' 
                          placeholder='Bank Name'
                          value={bank}
                          onChange={(e) => setBankname(e.target.value)}
                        />
                      </div>
                      <div className='bank-second-input-s'>
                        <p>Account Number</p>
                        <input 
                          className='input' 
                          type='text' 
                          placeholder='Account Number'
                          value={accno}
                          onChange={(e) => setAccno(e.target.value)}
                        />
                      </div>
                      <div className='bank-second-input-t'>
                        <p>IFSC Code</p>
                        <input 
                          className='input' 
                          type='text' 
                          placeholder='IFSC Code'
                          value={ifsc}
                          onChange={(e) => setIfsc(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                )
              }
              {
                showReportingPerson && (
                  <div className='show-reporting-person'>
                    <h1 className='r-h'>Reporting Person</h1> 
                    <div className='reporting-second-section'>
                      <input 
                        className='input' 
                        type='text' 
                        placeholder='Reporting Person'
                        value={rperson}
                        onChange={(e) => setRperson(e.target.value)}
                      />
                      <button 
                        className='submit-btn-add' 
                        type='submit'
                        onSubmit={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
      </form> 
    </div>
    </div>
    </div>
  )
}

export default Home;