import React from 'react'
import Header from './Header'
import Sidebar from './SideBar'
import './css/dashboard.css'
import './css/Note.css'
import { Link } from 'react-router-dom'

const SupplierNote = () => {
  return (
    <div className="dashboard-root">
      <Header />
        <div className="main-d">
          <Sidebar />
          <div className='note-main'>
            <div className='note-top'>
                <button className='note-new-btn'>
                    <Link to="/new-note">New</Link>
                </button>
                <div className='note-select'>
                    <label style={{fontWeight:'bold', marginBottom:'5px', paddingTop: '5%'}}>Search City</label>
                    <select className='note-city-select'>
                        <option>All</option>
                        <option>Mumbai</option>
                        <option>Pune</option>
                        <option>Delhi</option>
                    </select>
                </div>
                <div className='note-search'>
                    <label style={{fontWeight:'bold', marginBottom:'5px', paddingTop: '5%'}}>Search Customer</label>
                    <input
                        className='note-customer-search-input'
                        placeholder='Search Customer'
                    />
                </div>
                <button className='note-back-btn'><Link to="/Supplier">Back</Link></button>
            </div>
            <div className='note-table'>
              <div className='overflow-x-auto'>
                <table className='note-table-data'>
                  <thead>
                    <tr className='table-heading'>
                      <th>Sr No.</th>
                      <th>Date</th>
                      <th>Quote No.</th>
                      <th>Customer Name</th>
                      <th>City</th>
                      <th>Mobile No.</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default SupplierNote