import React, { useState } from "react";
import "./css/dashboard.css";
import "./css/Purchase.css";
import Header from "./Header";
import Sidebar from "./SideBar";
import { Link } from "react-router-dom";
import { FaTrash, FaEdit, FaWhatsapp, FaPrint } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";

const Purchase = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false); // For delete confirmation
  const [showPrintModal, setShowPrintModal] = useState(false); // For print modal
  const [purchaseToDelete, setPurchaseToDelete] = useState(null); // To store the ID of the purchase to delete
  const [selectedPurchase, setSelectedPurchase] = useState(null); // To store the selected purchase for print
  const [showPaymentModal, setShowPaymentModal] = useState(false); // For payment modal
  const [selectedPurchaseForPayment, setSelectedPurchaseForPayment] = useState(null); // To store the selected purchase for payment

  // Dummy data for the table
  const dummyData = [
    {
      _id: 1,
      srNo: 1,
      date: "2023-10-01",
      quoteNo: "Q1001",
      partyName: "ABC Purchases",
      materialName: "Steel",
      qty: 100,
      amount: 50000,
    },
    {
      _id: 2,
      srNo: 2,
      date: "2023-10-05",
      quoteNo: "Q1002",
      partyName: "XYZ Traders",
      materialName: "Copper",
      qty: 50,
      amount: 75000,
    },
    {
      _id: 3,
      srNo: 3,
      date: "2023-10-10",
      quoteNo: "Q1003",
      partyName: "PQR Industries",
      materialName: "Aluminum",
      qty: 200,
      amount: 100000,
    },
  ];

  const handleSearch = () => {
    // Add search functionality here
    console.log("Searching with:", { fromDate, toDate, searchQuery });
  };

  // Filter data based on search query
  const filteredData = dummyData.filter((row) =>
    row.partyName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle Print button click
  const handlePrintClick = (purchase) => {
    setSelectedPurchase(purchase);
    setShowPrintModal(true);
    console.log("Printing purchase:", purchase);
    // Add print functionality here
  };

  // Handle Delete button click
  const handleDeleteClick = (id) => {
    setPurchaseToDelete(id);
    setShowModal(true);
    console.log("Deleting purchase with ID:", id);
    // Add delete functionality here
  };

  // Handle Payment button click
  const handlePaymentClick = (purchase) => {
    setSelectedPurchaseForPayment(purchase);
    setShowPaymentModal(true);
    console.log("Processing payment for:", purchase);
    // Add payment functionality here
  };

  // Confirm delete action
  const confirmDelete = () => {
    console.log("Deleting purchase with ID:", purchaseToDelete);
    setShowModal(false);
    // Add delete logic here
  };

  // Cancel delete action
  const cancelDelete = () => {
    setShowModal(false);
  };

  return (
    <div className="dashboard-root">
      <Header />
      <div className="main-d">
        <Sidebar />
        <div className="purchase-container">
          <div className="purchase-header">
            <button className="new-purchase-btn">New Purchase</button>
            <div className="search-filters">
              <div className="date-range">
                <div className="date-field">
                  <label>From</label>
                  <input
                    type="date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </div>
                <div className="date-field">
                  <label>To</label>
                  <input
                    type="date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="supplier-search">
                <label>Search Purchase</label>
                <input
                  type="text"
                  placeholder="Enter purchase name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
          <table className="purchase-table">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Date</th>
                <th>Quote NO.</th>
                <th>Party Name</th>
                <th>Material Name</th>
                <th>QTY</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row) => (
                <tr key={row._id}>
                  <td>{row.srNo}</td>
                  <td>{row.date}</td>
                  <td>{row.quoteNo}</td>
                  <td>{row.partyName}</td>
                  <td>{row.materialName}</td>
                  <td>{row.qty}</td>
                  <td>{row.amount}</td>
                  <td>
                    <button
                      style={{ color: "green", margin: "0 5px" }}
                      onClick={() => handlePaymentClick(row)}
                    >
                      <FaWhatsapp />
                    </button>
                    <button
                      style={{ color: "black", margin: "0 5px" }}
                      onClick={() => handlePrintClick(row)}
                    >
                      <FaPrint />
                    </button>
                    <button
                      style={{ color: "red", margin: "0 5px" }}
                      onClick={() => handleDeleteClick(row._id)}
                    >
                      <FaTrash />
                    </button>
                    <Link to={`/edit-purchase/${row._id}`}>
                      <button style={{ color: "#007bff", margin: "0 5px" }}>
                        <FaEdit />
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>Are you sure you want to delete this purchase?</h3>
            <div className="modal-buttons">
              <button onClick={confirmDelete}>Yes</button>
              <button onClick={cancelDelete}>No</button>
            </div>
          </div>
        </div>
      )}

      {/* Print Modal */}
      {showPrintModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>Print Purchase Details</h3>
            <p>Quote No: {selectedPurchase?.quoteNo}</p>
            <p>Party Name: {selectedPurchase?.partyName}</p>
            <p>Amount: {selectedPurchase?.amount}</p>
            <button onClick={() => setShowPrintModal(false)}>Close</button>
          </div>
        </div>
      )}

      {/* Payment Modal */}
      {showPaymentModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>Process Payment</h3>
            <p>Party Name: {selectedPurchaseForPayment?.partyName}</p>
            <p>Amount: {selectedPurchaseForPayment?.amount}</p>
            <button onClick={() => setShowPaymentModal(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Purchase;