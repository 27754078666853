import React from "react";
import SuccessPopup from "./css/SuccessPopup.css";
const Success = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Submit Successfully!</h2>
        <button className="popup-close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Success;