import React, { useState } from "react";
import "./css/ShowMaintenance.css";
import "./css/dashboard.css";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Sidebar from "./SideBar";
import Header from "./Header";
import MaintenanceFormModal from "./MaintenanceFormModal";

const MaintenancePage = () => {
  const [maintenanceToEdit, setMaintenanceToEdit] = useState(null);
  const [showEditMaintenanceModal, setShowEditMaintenanceModal] = useState(false);
  const [vendorToDelete, setVendorToDelete] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showNewMaintenanceModal, setShowNewMaintenanceModal] = useState(false);
  const [data, setData] = useState([
    {
      id: 1,
      category: "Machine",
      date: "2024-01-01",
      amount: 1000,
      details: "belt",
    },
    {
      id: 2,
      category: "Machine",
      date: "2024-01-02",
      amount: 1200,
      details: "belt",
    },
  ]);
  const [form, setForm] = useState({
    category: "",
    date: "",
    amount: "",
    details: "",
  });
  const [errors, setErrors] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleNewMaintenanceSubmit = (newData) => {
    setData([...data, { ...newData, id: data.length + 1 }]);
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const validate = () => {
    const errors = {};
    if (!form.category) errors.category = "Category is required";
    if (!form.date) errors.date = "Date is required";
    if (!form.amount) errors.amount = "Amount is required";
    if (!form.details) errors.details = "Details are required";
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      setData([...data, { ...form, id: data.length + 1 }]);
      setForm({ category: "", date: "", amount: "", details: "" });
    } else {
      setErrors(validationErrors);
    }
  };

  const handleEditClick = (item) => {
    setMaintenanceToEdit(item);
    setShowEditMaintenanceModal(true);
  };

  const handleDeleteClick = (id) => {
    setVendorToDelete(id);
    setShowModal(true);
  };

  const handleDeleteConfirm = () => {
    setData(data.filter((item) => item.id !== vendorToDelete));
    setShowModal(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = data.filter((item) =>
    item.category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="dashboard-root">
      <Header />
      <div className="main-d">
        <Sidebar />
        <div className="showmaintenance-container">
          <div className="showmaintenance-header">
            <button className="shownew-maintenance" onClick={() => setShowNewMaintenanceModal(true)}>New Maintenance</button>
            <button className="showprint-statement">Print Statement</button>
            <button className="back">&larr;<Link to="/Maintenance">Back</Link></button>
          </div>
          <div className="filters">
            <div>
              <label htmlFor="city" className="bold-label">
                Select City
              </label>
              <select id="city" className="filter-dropdown input-style">
                <option>All</option>
                {/* Add more city options here */}
              </select>
            </div>
            <div>
              <label htmlFor="fromDate" className="bold-label">
                From Date
              </label>
              <input
                id="fromDate"
                type="date"
                className="filter-input input-style"
              />
            </div>
            <div>
              <label htmlFor="toDate" className="bold-label">
                To Date
              </label>
              <input
                id="toDate"
                type="date"
                className="filter-input input-style"
              />
            </div>
            <div>
              <label htmlFor="category" className="bold-label">
                Select Category
              </label>
              <select id="category" className="filter-dropdown input-style">
                <option>All</option>
                {/* Add more category options here */}
              </select>
            </div>
          </div>

          <div className="showmaintenance-table">
            <div
              className="overflow-x-auto"
              style={{
                borderRadius: "8px",
              }}
            >
              <table className="showmaintenance-table-data">
                <thead>
                  <tr className="showmaintenance-table-heading">
                    <th>Sr No.</th>
                    <th>Category Name</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Details</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="showmaintenance-data">
                  {currentItems.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.category}</td>
                      <td>{item.date}</td>
                      <td>{item.amount}</td>
                      <td>{item.details}</td>
                      <td>
                        <button
                          style={{ color: '#007bff', margin: '0 5px' }}
                          onClick={() => handleEditClick(item)}
                        >
                          <FaEdit />
                        </button>
                        <button
                          style={{ color: 'red', margin: '0 5px' }}
                          onClick={() => handleDeleteClick(item.id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="3"></td>
                    <td>Total</td>
                    <td>
                      {data.reduce(
                        (sum, item) => sum + parseFloat(item.amount),
                        0
                      )}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {filteredData.length > itemsPerPage && (
            <div className="pagination-controls">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>
                Page {currentPage} of {Math.ceil(filteredData.length / itemsPerPage)}
              </span>
              <button
                onClick={() =>
                  setCurrentPage((prev) =>
                    Math.min(prev + 1, Math.ceil(filteredData.length / itemsPerPage))
                  )
                }
                disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
              >
                Next
              </button>
            </div>
          )}
          {showModal && (
            <div className="show-modal">
              <div className="show-modal-content">
                <p>Are you sure you want to delete this maintenance record?</p>
                <button onClick={handleDeleteConfirm}>Yes</button>
                <button onClick={() => setShowModal(false)}>No</button>
              </div>
            </div>
          )}
          {showNewMaintenanceModal && (
            <MaintenanceFormModal
              isOpen={showNewMaintenanceModal}
              onClose={() => setShowNewMaintenanceModal(false)}
              onSubmit={handleNewMaintenanceSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MaintenancePage;