import { myAxios } from './helper';

export const addVehicle = async (data) => {
    try {
        const response = await myAxios.post('/api/vehicles/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getVehicle = async () => {
  try {
    const response = await myAxios.get('/api/vehicles/all');
    return response.data;
  } catch (error) {
      throw error;
  }
};


export const deleteVehicle = async (id) => {
    try{
        const response = await myAxios.delete(`/api/vehicles/delete/${id}`);
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : { error: 'Error deleting vehicle'}
    }
}

export const updateVehicle = async (id, vehicleData) => {
    try{
        const response = await myAxios.put(`/api/vehicles/edit/${id}`, vehicleData)
        return response.data;
    } catch (error) {
        console.error('Error updating vehicle: ', error);
        throw error;
    }
}