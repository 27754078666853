import React from 'react';
import './css/count.css';
import { FaUser, FaTruck, FaComment, FaMoneyBillWave, FaCreditCard, FaMoneyCheck } from 'react-icons/fa';  // Import different icons

const DashboardCount = () => {
  const data = [
    { title: 'Customer', value: '30', icon: <FaUser /> },
    { title: 'Supplier', value: '14', icon: <FaTruck /> },
    { title: 'SMS', value: '131', icon: <FaComment /> },
    { title: 'Dues', value: '1,101,712.00', icon: <FaMoneyBillWave /> },
    { title: 'Payable', value: '1,518,380.00', icon: <FaCreditCard /> },
    { title: 'Expense', value: '21,922.00', icon: <FaMoneyCheck /> }
  ];

  return (
    <div className="count-page-container">
      <div className="count-main">
        <table className="count-table">
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td className="table-cell">
                  <div className="title-with-icon">
                    {item.icon}
                    <span>{item.title}</span> {/* Title next to the icon */}
                  </div>
                </td>
                <td className="table-cell">{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DashboardCount;
