import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaAlignJustify,
  FaAngleDoubleLeft,
  FaRecycle,
  FaAngleDoubleRight,
  FaTruckArrowRight,
  FaAngleLeft,
} from "react-icons/fa";
import { BsTruck } from "react-icons/bs";
import { TfiHeadphone } from "react-icons/tfi";
import { VscFile } from "react-icons/vsc";
import { MdEmojiTransportation } from "react-icons/md";
import { GrVmMaintenance } from "react-icons/gr";
import { TbReportAnalytics } from "react-icons/tb";
import { LuUser2 } from "react-icons/lu";
import { GiHistogram } from "react-icons/gi";
import { PiTagBold } from "react-icons/pi";
import {
  HiOutlineCurrencyRupee,
  HiOutlineDocumentChartBar,
} from "react-icons/hi2";
import { BsSave2 } from "react-icons/bs";
import { RxDashboard } from "react-icons/rx";
import { RiBillLine } from "react-icons/ri";
import { IoIosArrowDown } from "react-icons/io";
import "./css/mainsidebar.css";

import {
  FaChartLine,
  FaFileContract,
  FaDollarSign,
  FaBoxOpen,
  FaShoppingCart,
  FaUsers,
  FaHandshake,
  FaCubes,
  FaTruckMoving,
  FaTools,
  FaShippingFast,
  FaTrashRestore,
  FaChartBar,
  FaHeadset,
} from "react-icons/fa";

const Sidebar = () => {
  const [isQuotationOpen, setIsQuotationOpen] = useState(false);
  const [isSalesopen, setIsSalesopen] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipTimeout, setTooltipTimeout] = useState(null);

  const handleMouseEnter = () => {
    const timeoutId = setTimeout(() => {
      setShowTooltip(true);
    }, 500);
    setTooltipTimeout(timeoutId);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
    if (tooltipTimeout) {
      clearTimeout(tooltipTimeout);
    }
  };

  const closeModal = () => {
    setShowCard(false);
  };

  const toggleCard = () => {
    setShowCard(!showCard);
  };

  const toggleQuotationDropdown = () => {
    setIsQuotationOpen(!isQuotationOpen);
  };

  const toggleSales = () => {
    setIsSalesopen(!isSalesopen);
  };

  const Sidebarhandle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex sidebar">
      <div
        className={`sidebar-container bg-gray-800 text-white ${
          isSidebarOpen ? "w-64" : "w-20"
        } transition-all duration-300 ease-in-out overflow-hidden`}
      >
        {!isSidebarOpen && (
          <button
            className="absolute left-4 bg-gray-800 text-white p-2"
            style={{
              top: "49px",
              height: "20px",
              width: "20px",
              marginLeft: "9px",
            }}
            onClick={Sidebarhandle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {showTooltip && (
              <div className="absolute left-16 bg-yellow-500 text-white text-xs rounded p-1">
                Open Sidebar
              </div>
            )}
            <FaAlignJustify size={19} />
          </button>
        )}
        <div className="p-4 text-center" style={{ backgroundColor: "black" }}>
          <h2 className="flex items-center text-xl font-semibold mt-2">
            <span className="flex-grow" style={{ marginLeft: "15%" }}>
              <span className="bg-black">Innovativ</span>
              <span className="text-white">Soft</span>
            </span>
            <span
              className="text-2xl mr-2 cursor-pointer"
              onClick={Sidebarhandle}
              style={{ marginLeft: "4%" }}
            >
              <FaAngleDoubleLeft size={20} />
            </span>
          </h2>
        </div>
        <ul className="mt-4">
          {/* Dashboard */}
          <li
            className="px-3 py-2 hover:bg-gray-700 flex items-center"
            style={{ paddingLeft: "19%" }}
          >
            <Link
              to="/dashboard"
              className="flex items-center"
              style={{ marginTop: "0.2rem" }}
            >
              <FaChartLine size={20} className="icon" />
              {isSidebarOpen && <span className="ml-3">Dashboard</span>}
            </Link>
          </li>

          {/* Quotation */}
          <li
            className="px-4 py-4 hover:bg-gray-700"
            style={{ paddingLeft: "19%" }}
          >
            <button
              className="w-full text-left focus:outline-none flex items-center"
              onClick={toggleQuotationDropdown}
            >
              <FaFileContract size={20} className="icon" />
              {isSidebarOpen && (
                <>
                  <span className="ml-3">Quotation</span>
                  <IoIosArrowDown size={14} className="ml-2" />
                </>
              )}
            </button>
            {isQuotationOpen && isSidebarOpen && (
              <ul className="mt-2 ml-6 bg-gray-700 rounded-lg">
                <li className="px-4 py-2 hover:bg-gray-600">
                  <Link to="/new-quotation" className="flex items-center">
                    <HiOutlineDocumentChartBar size={20} className="icon" />
                    <span className="ml-3">New Quotation</span>
                  </Link>
                </li>
                <li className="px-4 py-2 hover:bg-gray-600">
                  <Link to="/viewquotation" className="flex items-center">
                    <RiBillLine size={20} className="icon" />
                    <span className="ml-3">View Quotation</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* Sales */}
          <li
            className="px-4 py-2 hover:bg-gray-700"
            style={{ paddingLeft: "19%" }}
            onClick={toggleSales}
          >
            <Link to="/sales" className="flex items-center">
              <FaDollarSign size={20} className="icon" />
              {isSidebarOpen && (
                <>
                  <span className="ml-3">Sales</span>
                  <IoIosArrowDown size={14} className="ml-2" />
                </>
              )}
            </Link>
            {isSalesopen && isSidebarOpen && (
              <ul className="mt-2 ml-6 bg-gray-700 rounded-lg">
                <li className="px-4 py-2 hover:bg-gray-600">
                  <Link to="/new-sales" className="flex items-center">
                    <HiOutlineDocumentChartBar size={20} className="icon" />
                    <span className="ml-3">New Sales</span>
                  </Link>
                </li>
                <li className="px-4 py-2 hover:bg-gray-600">
                  <Link to="/viewquotation" className="flex items-center">
                    <RiBillLine size={20} className="icon" />
                    <span className="ml-3">View Sales</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>

         
          {/* Purchase */}
          <li
            className="px-4 py-2 hover:bg-gray-700"
            style={{ paddingLeft: "19%" }}
          >
            <Link to="/Purchase" className="flex items-center">
              <FaShoppingCart size={20} className="icon" />
              {isSidebarOpen && <span className="ml-3">Purchase</span>}
            </Link>
          </li>

          {/* Customer */}
          <li
            className="px-4 py-2 hover:bg-gray-700"
            style={{ paddingLeft: "19%" }}
          >
            <Link to="/Customer" className="flex items-center">
              <FaUsers size={20} className="icon" />
              {isSidebarOpen && <span className="ml-3">Customer</span>}
            </Link>
          </li>

          {/* Supplier */}
          <li
            className="px-4 py-2 hover:bg-gray-700"
            style={{ paddingLeft: "19%" }}
          >
            <Link to="/supplier" className="flex items-center">
              <FaHandshake size={20} className="icon" />
              {isSidebarOpen && <span className="ml-3">Supplier</span>}
            </Link>
          </li>

          {/* Material */}
          <li
            className="px-4 py-2 hover:bg-gray-700"
            style={{ paddingLeft: "19%" }}
          >
            <Link to="/Material" className="flex items-center">
              <FaCubes size={20} className="icon" />
              {isSidebarOpen && <span className="ml-3">Material</span>}
            </Link>
          </li>

          {/* Vehicle */}
          <li
            className="px-4 py-2 hover:bg-gray-700"
            style={{ paddingLeft: "19%" }}
          >
            <Link to="/vehicle" className="flex items-center">
              <FaTruckMoving size={20} className="icon" />
              {isSidebarOpen && <span className="ml-3">Vehicle</span>}
            </Link>
          </li>

          {/* Maintenance */}
          <li
            className="px-4 py-2 hover:bg-gray-700"
            style={{ paddingLeft: "19%" }}
          >
            <Link to="/Maintenance" className="flex items-center">
              <FaTools size={20} className="icon" />
              {isSidebarOpen && <span className="ml-3">Maintenance</span>}
            </Link>
          </li>

          {/* Transport */}
          <li
            className="px-4 py-2 hover:bg-gray-700"
            style={{ paddingLeft: "19%" }}
          >
            <Link to="/transport" className="flex items-center">
              <FaShippingFast size={20} className="icon" />
              {isSidebarOpen && <span className="ml-3">Transport</span>}
            </Link>
          </li>

          {/* Recycle Bin */}
          <li
            className="px-4 py-2 hover:bg-gray-700"
            style={{ paddingLeft: "19%" }}
          >
            <Link to="/recycle-bin" className="flex items-center">
              <FaTrashRestore size={20} className="icon" />
              {isSidebarOpen && <span className="ml-3">Recycle Bin</span>}
            </Link>
          </li>

           {/* Add */}
           <li
            className="px-4 py-2 hover:bg-gray-700"
            style={{ paddingLeft: "19%" }}
          >
            <Link to="/add" className="flex items-center">
              <FaBoxOpen size={20} className="icon" />
              {isSidebarOpen && <span className="ml-3">Employee</span>}
            </Link>
          </li>

          <li
            className="px-4 py-2 hover:bg-gray-700"
            style={{ paddingLeft: "19%" }}
          >
            <Link to="/Inventory" className="flex items-center">
              <FaBoxOpen size={20} className="icon" />
              {isSidebarOpen && <span className="ml-3">Inventory</span>}
            </Link>
          </li>


          {/* Report */}
          <li
            className="px-4 py-2 hover:bg-gray-700"
            style={{ paddingLeft: "19%" }}
          >
            <Link
              to="/report"
              onClick={toggleCard}
              className="flex items-center"
            >
              <TbReportAnalytics size={20} className="icon" />
              {isSidebarOpen && <span className="ml-3">Report</span>}
            </Link>
          </li>

          {/* Support */}
          <li
            className="px-4 py-2 hover:bg-gray-700"
            style={{ paddingLeft: "19%" }}
          >
            <Link
              to="/support"
              onClick={toggleCard}
              className="flex items-center"
            >
              <FaHeadset size={20} className="icon" />
              {isSidebarOpen && <span className="ml-3">Support</span>}
            </Link>
            {showCard && (
              <div
                className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center p-12 z-50"
                onClick={closeModal}
              >
                <div
                  className="bg-white shadow-lg rounded-lg p-6 max-w-md w-full relative"
                  onClick={(e) => e.stopPropagation()}
                >
                  <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    onClick={closeModal}
                  >
                    &times;
                  </button>
                  <h1 className="text-2xl font-bold text-center">
                    <span className="text-black">Innovativ</span>
                    <span className="text-yellow-500">Soft</span>
                  </h1>
                  <div className="mt-4 text-center">
                    <p className="text-black font-bold">
                      📧 Email ID: contact@innovativsoft.com
                    </p>
                    <p className="text-black font-bold">
                      Contact No: +91 000-000-0000
                    </p>
                    <p className="text-black font-bold">
                      📍 Location: Ambegaon, Pune
                    </p>
                  </div>
                </div>
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
