import React, { useState } from "react";
import SuccessPopup from "./SuccessPopup";

const MaintenanceFormModal = ({ isOpen, onClose, onSubmit }) => {
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    onSubmit(data);
    setShowSuccessPopup(true);
  };

  const handleClosePopup = () => {
    setShowSuccessPopup(false);
    onClose();
  };

  return (
    <>
      <div className="remote-modal-overlay">
        <div className="remote-modal-content">
          <div className="remote-modal-header">
            <h2>Maintenance Entry</h2>
            <button className="remote1-close-button" onClick={onClose}>×</button>
          </div>
          <form className="remote-modal-body" onSubmit={handleSubmit}>
            <div className="remote-form-row">
              <label className="remote-form-label">
                Date
                <input type="date" name="date" required />
              </label>
              <label className="remote-form-label">
                Vendor
                <input type="text" name="vendor" required />
              </label>
            </div>
            <div className="remote-form-row">
              <label className="remote-form-label">
                Mobile no.
                <input type="text" name="mobile" required />
              </label>
              <label className="remote-form-label">
                Select Category
                <select name="category" required>
                  <option value="">None</option>
                  <option value="Machine">Machine</option>
                  <option value="Other">Other</option>
                </select>
              </label>
              <label className="remote-form-label">
                Enter Amount
                <input type="number" name="amount" required />
              </label>
            </div>
            <div className="remote-form-row">
              <label className="remote-form-label">
                Details
                <textarea name="details" required />
              </label>
            </div>
            <div className="remote-button-group">
              <button type="submit" className="remote-save-button">Save</button>
              <button type="button" className="remote-close-button" onClick={onClose}>Close</button>
            </div>
          </form>
        </div>
      </div>

      <SuccessPopup isOpen={showSuccessPopup} onClose={handleClosePopup} />
    </>
  );
};

export default MaintenanceFormModal;