import { myAxios } from "./helper";

export const addCustomer = async (data) => {
    try{
        const response = await myAxios.post('/api/customers/add', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getCustomers = async () => {
    try {
        const response = await myAxios.get('/api/customers/all');
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getCustomer = async (id) => {
    try {
      const response = await myAxios.get(`/api/customers/edit/${id}`);
      return response.data; 
    } catch (error) {
      console.error('Error fetching customer:', error);
      throw error;
    }
};

export const updateCustomer = async (id, customerData) => {
    try {
      const response = await myAxios.put(`/api/customers/edit/${id}`, customerData);
      return response.data;
    } catch (error) {
      console.error('Error updating customer:', error);
      throw error;
    }
};

export const deleteCustomer = async (id) => {
    try {
        const response = await myAxios.delete(`/api/customers/delete/${id}`);
        return response.data
    } catch (error) {
        throw error;
    }
}


export const updatePayment = async (customerId, paymentData) => {
  try {
    const response = await myAxios.post(`/api/customers/${customerId}/payment`, {
      paymentData, 
    });
    console.log('Payment updated successfully:', response.data);
    return response.data; 
  } catch (error) {
    console.error('Error updating payment:', error.response || error.message);
    throw error; 
  }
};



