import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from "./components/Login";
import Forgot from "./components/Forgot";
import DashboardPage from "./components/DashboardPage";
import NewQuotation from "./components/NewQuotation";
import ViewQuotation from "./components/ViewQuotation";
import Home from "./components/Home";
import Customer from "./components/Customer";
import AddCustomer from "./components/AddCustomer";
import Note from "./components/Note";
import NewNote from "./components/NewNote";
import EditCustomer from "./components/EditCustomer";
import Material from "./components/Material";
import Sales from "./components/Sales";
import Vehicle from "./components/Vehicle";
import AddVehicle from "./components/AddVehicle";
import DashboardSales from "./components/DashboardSales";
import Dashboardrevenue from"./components/Dashboardrevenue";
import DashboardCount from "./components/dashboardCount";
import DBProductPurchase from "./components/DBProductPurchase"
import RatingsPage from "./components/RatingsPage";
import Add from "./components/Add"; 
import './App.css'
import './components/css/dashboard.css'
import Inventroy from "./components/Inventory";
import Maintenance from "./components/Maintenance";
import ShowMaintenance from "./components/ShowMaintenance";
import Addvendor from "./components/Addvendor";
import Supplier from "./components/Supplier";
import SupplierNote from "./components/Supplier-Note";
import AddSupplier from "./components/NewSupplier"
import Purchase from "./components/Purchase";

function App() {
  return (
    <div className="classname">
      <div className="root-main">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/" element={<Login/>} />
            <Route path="/" element={<Forgot/>} />
            <Route path="/dashboardsales" element={<DashboardSales />} />
            <Route path="/dashboard" element={<DashboardPage/>} />
            <Route path="/revenue" element={<Dashboardrevenue/>} />
            <Route path="/dashboardcount" element={<DashboardCount/>} />
            <Route path="/dbproduct" element={<DBProductPurchase/>} />
            <Route path="/ratings" element={<RatingsPage/>} />
            <Route path="/new-quotation" element={<NewQuotation/>} />
            <Route path="/viewquotation" element={<ViewQuotation/>} />
            <Route path="/customer" element={<Customer/>} />
            <Route path="/new-customer" element={<AddCustomer/>} />
            <Route path="/edit-customer/:id" element={<EditCustomer/>} />
            <Route path="/note" element={<Note/>} />
            <Route path="/new-note" element={<NewNote/>} />
            <Route path="/material" element={<Material/>} />
            <Route path="/sales" element={<Sales/>} />
            <Route path="/vehicle" element={<Vehicle/>} />
            <Route path="/add-vehicle" element={<AddVehicle/>} />
            <Route path="/add" element={<Add/>} />
            <Route path="/Inventory" element={<Inventroy/>} />
            <Route path="/Maintenance" element={<Maintenance/>} />
            <Route path="/Show-Maintenance" element={<ShowMaintenance/>} />
            <Route path="/add-vendor" element={<Addvendor/>} />
            <Route path="/Customer" element={<Customer/>} />
            <Route path="/AddCustomer" element={<AddCustomer/>} />
            <Route path="/Supplier" element={<Supplier />} />
            <Route path="/SupplierNote" element={<SupplierNote/>} />
            <Route path="/new-supplier" element={<AddSupplier/>} />
            <Route path="/Purchase" element={<Purchase/>} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
