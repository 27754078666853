import { myAxios } from './helper'; 

// API call to fetch the username from the backend
export const getUsername = async () => {
  try {
    const response = await myAxios.get('/api/user/username');
    return response.data;  // Return the username
  } catch (error) {
    console.error("Error fetching username:", error);
    throw error;  // Rethrow error to be handled by the calling component
  }
};
