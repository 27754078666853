import React, { useState } from 'react'
import './css/dashboard.css'
import Sidebar from './SideBar'
import Header from './Header'
import './css/AddCustomer.css'
import { Link } from 'react-router-dom'
import { addCustomer } from '../services/customer'
import { FaAngleLeft } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

const AddCustomer = () => {
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [city, setCity] = useState('')
    const [openingDues, setOpeningDues] = useState('')
    const [address, setAddress] = useState('')


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const customerData = { name, contact, city, openingDues, address };
            const response = await addCustomer(customerData);
            console.log('Customer added successfully:', response);
        } catch (error) {
            console.error('Error adding customer:', error)
        }
    }

  return (
    <div className="dashboard-root">
      <Header />
        <div className="main-d">
          <Sidebar />
          <div className='add-customer-main'>
            <div className='add-customer-top'>
                <button className='add-customer-back'>
                    <Link to="/customer">
                        <div style={{display: 'flex', alignItems:'center', gap:'5px'}}>
                            <FaAngleLeft/> 
                            <p>Back</p>
                        </div>
                    </Link>
                </button>
                <button className='add-customer-close'>
                    <Link to="/dashboard">
                        <div style={{display: 'flex', alignItems:'center', gap:'5px'}}>
                            <p>Close</p>
                            <FaXmark/>
                        </div>
                    </Link>
                </button>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='add-customer-first-row'>
                    <div className='add-customer-name'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Customer Name</label>
                        <input 
                            className='customer-first-row-input'
                            placeholder='Enter Name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className='add-customer-phone'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Contact Number</label>
                        <input 
                            placeholder='Enter Number'
                            className='customer-first-row-input'
                            value={contact}
                            onChange={(e) => setContact(e.target.value)}
                        />
                    </div>
                    <div className='add-customer-city'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>City</label>
                        <input 
                            placeholder='Enter City'
                            className='customer-first-row-input'   
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </div>
                </div>
                <div className='add-customer-second-row'>
                    <label style={{fontWeight:'bold', marginBottom:'5px'}}>Opening Dues</label>
                    <input 
                        className='opening-dues-input' 
                        placeholder='Opening Dues'
                        value={openingDues}
                        onChange={(e) => setOpeningDues(e.target.value)}
                    />
                </div>
                <div className='add-customer-third-row'>
                    <label style={{fontWeight:'bold', marginBottom:'5px'}}>Customer Address</label>
                    <textarea  
                        className='add-customer-address-input' 
                        placeholder='Enter Address' rows="4"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    >
                    </textarea>
                </div>
                <div className='add-customer-fourth-row'>
                <button className='add-customer-cancel'>
                    <Link to="/customer">Cancel</Link>
                </button>
                <button className='add-customer-save' type="submit">Save</button>
            </div>
            </form>
          </div>
        </div>
    </div>
  )
}

export default AddCustomer