import React from 'react'
import Sidebar from './SideBar'
import Header from './Header'
import './css/dashboard.css'
import { FaAngleLeft } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import './css/Inventory.css'
const Inventroy = () => {
  return (
    <div className="dashboard-root">
      <Header />
      <div className="main-d">
        <Sidebar />
        <div className="add-Inventroy-main">
            <div className='add-Inventroy-top'>
                <div className='add-Inventroy-top-left'>
                    <button className='add-customer-back'>
                        <div style={{display: 'flex', alignItems:'center', gap:'5px'}}>
                            <FaAngleLeft/> 
                            <p>Back</p>
                        </div>
                    </button>
                </div>
                <div className='add-Inventroy-top-right'>
                    <button className='add-Inventroyr-close'>
                        <div style={{display: 'flex', alignItems:'center', gap:'5px', fontWeight: 'bold', color: 'red'}}>
                            <p>Close</p>
                            <FaXmark/>
                        </div>
                    </button>
                </div>
            </div>
            <form>
                <div className='add-Inventroy-first-input'>
                    <div className='a-i-request-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Requested By</label>
                        <input
                            className='ai-request-input'
                            placeholder='Requested by'
                            
                        />
                    </div>
                    <div className='a-i-issued-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Issued Of Work</label>
                        <input
                            className='ai-issued-input'
                            placeholder='Issued of work'
                            
                        />
                    </div>
                    <div className='a-i-department-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Department</label>
                        <select
                            className='ai-department-input'
                            placeholder='Department'
                        >
                            <option>Department 1</option>
                            <option>Department 2</option>
                            <option>Department 3</option>

                        </select>
                    </div>
                </div>
                <div className='add-Inventroy-second-input'>
                    <div className='a-i-item-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Item</label>
                        <input
                            className='ai-item-input'
                            placeholder='Item'
                            
                        />
                    </div>
                    <div className='a-i-quantity-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Available Quantity</label>
                        <input
                            className='ai-quantity-input'
                            placeholder='Available quantity'
                            
                        />
                    </div>
                    <div className='a-i-Issue-Quantity-input'>
                        <label style={{fontWeight:'bold', marginBottom:'5px'}}>Issue Quantity</label>
                        <input
                            className='ai-Issue-Quantity-input'
                            placeholder='Issue quantity'
                            
                        />
                    </div>
                </div>
                <div className='add-Inventory-bottom'>
                    <button className='add-Inventory-cancel'>
                        Cancel
                    </button>
                    <button type='submit' className='add-Inventory-save'>Save</button>
                </div>
            </form>
        </div>
        </div>
    </div>
  )
}

export default Inventroy