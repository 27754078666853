import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import "./css/dashboardsales.css";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

const DashboardPage = () => {
  const [startDate, setStartDate] = useState("2022-12-01");
  const [endDate, setEndDate] = useState("2022-12-06");
  const [timeframe, setTimeframe] = useState("6days");

  const salesData = {
    "6days": {
      labels: ["1 Dec", "2 Dec", "3 Dec", "4 Dec", "5 Dec", "6 Dec"],
      current: [300, 400, 320, 500, 450, 600],
      previous: [200, 300, 250, 450, 400, 500],
    },
    week: {
      labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
      current: [1800, 2000, 2200, 2500],
      previous: [1700, 1900, 2100, 2300],
    },
  };

  const filterSalesData = () => {
    const data = salesData[timeframe];

    if (timeframe === "6days") {
      const filteredData = data.labels.reduce(
        (acc, label, index) => {
          const labelDate = new Date(`2022-12-${label.split(" ")[0]}`);
          const start = new Date(startDate);
          const end = new Date(endDate);

          if (labelDate >= start && labelDate <= end) {
            acc.labels.push(label);
            acc.current.push(data.current[index]);
            acc.previous.push(data.previous[index]);
          }
          return acc;
        },
        { labels: [], current: [], previous: [] }
      );
      return filteredData;
    }

    return data;
  };

  const filteredData = filterSalesData();
  const totalCurrentSales = filteredData.current.reduce((a, b) => a + b, 0);
  const totalPreviousSales = filteredData.previous.reduce((a, b) => a + b, 0);

  const percentageChange = (
    ((totalCurrentSales - totalPreviousSales) / totalPreviousSales) *
    100
  ).toFixed(1);

  const isIncrease = totalCurrentSales > totalPreviousSales;

  const handleTimeframeChange = (value) => {
    setTimeframe(value);
  };

  const chartData = {
    labels: filteredData.labels,
    datasets: [
      {
        label: "Current Sales",
        data: filteredData.current,
        borderColor: "#e6e8ec",
        fill: false,
        tension: 0.4,
      },
      {
        label: "Previous Sales",
        data: filteredData.previous,
        borderColor: "#5a6acf",
        fill: false,
        tension: 0.4,
      },
    ],
  };

  return (
   
        // <div className="dashboard-h">
          <div className="sales-section">
            <div className="sales-header">
              <h1><strong>Sales</strong></h1>
              <button className="view-report-btn">View Report</button>
            </div>

            <div className="sales-summary">
              <h3>Total Sales: {totalCurrentSales}</h3>
              <p>
                <span
                  className={`percentage-change ${
                    isIncrease ? "up" : "down"
                  }`}
                  style={{
                    color: isIncrease ? "green" : "red",
                  }}
                >
                  {isIncrease ? "↑" : "↓"} {Math.abs(percentageChange)}%
                </span>{" "}
                vs last week
              </p>
            </div>

            <div className="date-selector">
              <label>
                Start Date:{" "}
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </label>
              <label>
                End Date:{" "}
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </label>
            </div>

            <div className="sales-chart-container">
              <Line data={chartData} options={{ responsive: true }} />
            </div>

            <div className="timeframe-selector">
              <label>
                <input
                  type="radio"
                  name="timeframe"
                  value="6days"
                  checked={timeframe === "6days"}
                  onChange={() => handleTimeframeChange("6days")}
                />
                Last 6 Days
              </label>

              <label>
                <input
                  type="radio"
                  name="timeframe"
                  value="week"
                  checked={timeframe === "week"}
                  onChange={() => handleTimeframeChange("week")}
                />
                Last Week
              </label>
            </div>
          </div>
        // </div>
  );
};

export default DashboardPage;
