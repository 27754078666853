import React from 'react'
import Header from './Header'
import Sidebar from './SideBar'
import './css/dashboard.css'

const NewNote = () => {
  return (
    <div className="dashboard-root">
      <Header />
        <div className="main-d">
          <Sidebar />
            <div className='note-main'>

            </div>
        </div>
    </div>
  )
}

export default NewNote