import React from "react";
import "./css/RatingsPage.css";

const RatingPage = () => {
  const ratings = [
    { name: "Sales", percentage: 85, color: "#6463D6", size: 120, top: "50px", left: "10px" },
    { name: "Purchase", percentage: 85, color: "#F99C30", size: 180, top: "20px", left: "120px" },
    { name: "Fuel/Diesel", percentage: 92, color: "#2FBFDE", size: 140, top: "160px", left: "-30px" },
  ];

  return (
    <div className="rating-container">
      <h1 className="rating-title">Your Rating</h1>
      <p className="rating-description">Lorem ipsum dolor sit amet, consectetur</p>

      <div className="circle-container">
        {ratings.map((rating, index) => (
          <div
            key={index}
            className="circle"
            style={{
              width: `${rating.size}px`,
              height: `${rating.size}px`,
              border: `4px solid ${rating.color}`,
              top: rating.top,
              left: rating.left,
            }}
          >
            <div
              className="inner-circle"
              style={{
                backgroundColor: rating.color,
                width: `${rating.size - 10}px`,
                height: `${rating.size - 10}px`,
              }}
            >
              <span className="percentage">{rating.percentage}%</span>
              <span className="name">{rating.name}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RatingPage;
